import Vue from "vue"
import Router from "vue-router"
import preloader from "../plugins/preloader"
import { getLocalStorageElement } from "@/helpers/localstorage"
import { LSTORAGE } from "@/config/constants"
import { isServiceMode } from "@/helpers/dev"
// import AppLayoutLanding from "@/layouts/AppLayoutLanding.vue"
import HomeLayout from "@/layouts/HomeLayout.vue"
import AppLayoutSimple from "@/layouts/AppLayoutSimple.vue"
import AppLayoutDefault from "@/layouts/AppLayoutDefault.vue"
import AppLayoutService from "@/layouts/AppLayoutService.vue"
import store from "@/store"
// import i18n from "@/plugins/i18n"

Vue.use(Router)

const serviceMode = isServiceMode()
const serivceModeProps = () => {
    return serviceMode
        ? {
              redirect: (to) => {
                  return "service"
              }
          }
        : {}
}

const router = new Router({
    mode: "history",
    base: process.env.VUE_APP_BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: "/",
            redirect: { name: "landing" },
            name: "AppLayoutLanding",
            component: HomeLayout,
            children: [
                {
                    path: "/",
                    name: "landing",
                    component: () => import("@/pages/landing/Home.vue")
                },
                {
                    path: "/about",
                    name: "HomeAbout",
                    component: () => import("@/components/Home/Info/About.vue")
                },
                {
                    path: "/boost",
                    name: "HomeBoost",
                    component: () => import("@/components/Home/Info/Boost.vue")
                },
                {
                    path: "/overreal",
                    name: "HomeOverreal",
                    component: () => import("@/components/Home/Info/Overreal.vue")
                },
                {
                    path: "/support",
                    name: "HomeSupport",
                    component: () => import("@/components/Home/Info/Support.vue")
                },
                {
                    path: "/tactile",
                    name: "HomeTactile",
                    component: () => import("@/components/Home/Info/Tactile.vue")
                },
                {
                    path: "/terms",
                    name: "HomeTermsofservice",
                    component: () => import("@/components/Home/Info/Termsofservice.vue")
                },
                {
                    path: "/uniteverse",
                    name: "HomeUniteverse",
                    component: () => import("@/components/Home/Info/Uniteverse.vue")
                },
                {
                    path: "/web3info",
                    name: "HomeWeb3infosmartcontracts",
                    component: () => import("@/components/Home/Info/Web3infosmartcontracts.vue")
                },

            ],
        },
        {
            path: "/AppLayoutSimple",
            redirect: { name: "notFound" },
            name: "AppLayoutSimple",
            component: AppLayoutSimple,
            children: [
                {
                    path: "/signup",
                    name: "signup",
                    component: () => import("@/pages/register/Register.vue")
                },
                {
                    path: "/r/:referral",
                    name: "referral",
                    component: () => import("@/pages/register/RegisterAlighting.vue"),
                    props: true,
                    ...serivceModeProps()
                }
            ]
        },
        {
            path: "/AppLayoutDefault",
            redirect: { name: "notFound" },
            name: "AppLayoutDefault",
            component: AppLayoutDefault,
            children: [
                {
                    path: "/settings",
                    name: "settings",
                    component: () => import("@/pages/settings/Settings.vue"),
                    meta: {
                        requiresWallet: true,
                        requiresAuth: true
                    },
                    ...serivceModeProps()
                },
                {
                    path: "/home",
                    name: "dashboard",
                    meta: {
                        requiresWallet: true
                    },
                    component: () => import("@/pages/dashboard/Dashboard.vue"),
                    ...serivceModeProps(),
                },
                {
                    path: "/dashboard",
                    meta: {
                        requiresWallet: true
                    },
                    redirect: { name: "dashboard" },
                },
                {
                    path: "/systems",
                    name: "classic",
                    meta: {
                        requiresWallet: true
                    },
                    component: () => import("@/pages/dashboard/RealForce.vue"),
                    ...serivceModeProps()
                },
                {
                    path: "/systems/uniteverse",
                    name: "uniteverse",
                    meta: {
                        requiresWallet: true
                    },
                    component: () => import("@/pages/dashboard/UniteVerse.vue"),
                    ...serivceModeProps(),
                    beforeEnter(to, from,next) {
                        // if (process.env.VUE_APP_IS_UV_READY === 'true') {
                        //     location.href = process.env.VUE_APP_UV_LINK;
                        //     return false;
                        // }
                        location.href = process.env.VUE_APP_UV_LINK
                        next();
                    },
                },
                {
                    path: "/systems/forceсoin",
                    name: "forceсoin",
                    meta: {
                        requiresWallet: true
                    },
                    component: () => import("@/pages/dashboard/ForceCoin.vue"),
                    ...serivceModeProps(),
                    beforeEnter(to, from,next) {
                        // if (process.env.VUE_APP_IS_UV_READY === 'true') {
                        //     location.href = process.env.VUE_APP_FORCECOIN_LINK
                        //     return false;
                        // }
                        location.href = process.env.VUE_APP_FORCECOIN_LINK
                        next();
                    },
                },
                {
                    path: "/systems/Tactile",
                    name: "classicAllLevels",
                    meta: {
                        requiresWallet: true
                    },
                    component: () => import("@/pages/dashboard/RealForceAllLevels.vue"),
                    props: true,
                    ...serivceModeProps()
                },
                {
                    path: "/systems/Tactile/Level:lvl",
                    name: "classiclevel",
                    meta: {
                        requiresWallet: true
                    },
                    component: () => import("@/pages/dashboard/RealForceLevel.vue"),
                    props: true,
                    ...serivceModeProps()
                },
                {
                    path: "/systems/referral",
                    name: "systems_referral",
                    meta: {
                        requiresWallet: true
                    },
                    component: () => import("@/pages/dashboard/MyTeam.vue"),
                    ...serivceModeProps()
                },
                {
                    path: "/academy",
                    name: "academy",
                    component: () => import("@/pages/academy/TheAcademy.vue"),
                    props: true,
                    children: [
                        {
                            path: "/academy/:id",
                            name: "showCategory",
                            component: () => import("@/pages/academy/TheAcademy.vue"),
                            props: true
                        }
                    ]
                },
                {
                    path: "/academy/post/:postId",
                    name: "showPost",
                    component: () => import("@/pages/academy/Post.vue"),
                    props: true
                },
                {
                    path: "/reviews",
                    name: "reviews",
                    component: () => import("@/pages/reviews/Reviews.vue")
                },
                {
                    path: "/about",
                    name: "about",
                    component: () => import("@/pages/info/About.vue")
                },

                {
                    path: "/help",
                    name: "help",
                    component: () => import("@/pages/help/Help.vue"),
                    meta: {
                        requiresWallet: true,
                        requiresAuth: true
                    }
                },
                {
                    path: "/help/create",
                    name: "helpcreate",
                    component: () => import("@/pages/help/Create.vue"),
                    meta: {
                        requiresWallet: true,
                        requiresAuth: true
                    }
                },
                {
                    path: "/help/ticket:id",
                    name: "helpticket",
                    component: () => import("@/pages/help/Ticket.vue"),
                    meta: {
                        requiresWallet: true,
                        requiresAuth: true
                    }
                },
                {
                    path: "/systems/nfts",
                    name: "nfts",
                    meta: {
                        requiresWallet: true
                    },
                    component: () => import("@/pages/nfts/NftList.vue"),
                    ...serivceModeProps()
                },
                {
                    path: "/systems/nfts/:contractAddress-:tokenId",
                    name: "nft",
                    meta: {
                        requiresWallet: true
                    },
                    component: () => import("@/pages/nfts/Nft.vue"),
                    ...serivceModeProps()
                }
            ]
        },
        {
            path: "/AppLayoutService",
            redirect: { name: "notFound" },
            name: "AppLayoutService",
            component: AppLayoutService,
            children: [
                {
                    path: "/service",
                    name: "service",
                    component: () => import("@/pages/service/ServicePage.vue"),
                    meta: {
                        requiresWallet: false
                    }
                },
                {
                    path: "*",
                    name: "notFound",
                    component: () => import("@/pages/service/NotFoundPage.vue"),
                    meta: {
                        requiresWallet: false
                    }
                }
            ]
        }
    ]
})

let showed = false

router.beforeResolve((to, from, next) => {
    if (to.path && !showed) {
        preloader.show(true)
        showed = true
    }
    next()
})

router.beforeEach((to, from, next) => {
    function hasQueryParams(route) {
        return !!Object.keys(route.query).length
    }

    if (!to.matched.some((record) => record.meta.requiresWallet)) {
        next()
    } else {
        let lsMetamaskConnected = getLocalStorageElement(LSTORAGE.connected)

        const connected = store.getters["crypto/isConnected"] || lsMetamaskConnected

        if (connected) {
            if (!hasQueryParams(to) && hasQueryParams(from)) {
                next({ ...to, query: from.query })
            } else {
                next()
            }
            return
        }

        // @TODO add some alert
        // router.app.$swal(router.app.$t("errors.connectWalletError"))
        // router.app.$swal(i18n.$vm.t("errors.connectWalletError"))

        next("/academy")
    }
})

router.afterEach(() => {
    setTimeout(function() {
        preloader.show(false)
    }, 888)
})

export default router
