<template>
   <nav aria-label="breadcrumbs">
        <ul>
            <li v-if="forceIdDemo" class="forceidDemoMode"><p>{{ forceIdDemo }}</p><BaseIcon name="arrowRight" /></li>
            <li v-for="(crumb, index) in  crumbs" :key="index">
              <button
                @click="toRoute(crumb)"
                :class="crumb.isLast === true && 'span'"
              ><BaseIcon name="arrowRight" v-if="index !== 0"/>
                <p v-if="index !== 3">{{ crumb.label }}</p>
              </button>
            </li>
        </ul>
    </nav>
</template>

<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import { sliceString } from '@/helpers/sliceString'

export default {
  components: {
    BaseIcon,
  },
  data() {
    return {
      forceIdDemo: ''
    }
  },
  mounted() {
    if (this.$route.query.account) this.forceIdDemo = this.$route.query.account
  },
  computed: {
        crumbs() {
          const router = this.$route.path;
          const paths = router.split('/').filter(p => !!p)
          return paths.map((path, index) => {
            const isLast = index === paths.length - 1;
            let label = path;
            const routePath = `/${paths.slice(0, index + 1).join('/')}`;
            if (label.length > 15) {
              label = sliceString(label) 
            }
            return { label, route: routePath, isLast };
          })
        },
  },
  methods: {
    toRoute(route) {
      this.$router.push(route.route)
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  ul {
    color: #666666;
    width: 100%;
    display: flex;
    flex-direction: row;
    .forceidDemoMode {      
      display: flex;
      flex-direction: row;
      p {
          padding: 0;
          margin: 0 10px;
          line-height: 20px;
      }
      &:hover {
        color: #666666;
      }
      @media (max-width: 500px) {
        p {
          margin: 0;
        }
      }
    }
    li {
      transition: color 600ms;
      &:hover {
        color: var(--color-black-50);
      }
      button {
        display: flex;
        p {
          padding: 0;
          margin: 0 10px;
          line-height: 20px;
        }
        @media (max-width: 500px) {
          p {
            margin: 0;
          }
        }
      }
    }
  }
}

.span {
    color: var(--color-black-50);
}
</style>