<template>
  <div class="Footer">
    <div class="footer__top">
      <div class="footer__top-title">
        <p>{{ $t("home.footer.title") }}</p>
        <span>
          {{ $t("home.footer.desc") }}
        </span>
      </div>
      <router-link class="btn__default dark" tag="a" :to="{ name: 'signup' }">
        {{ $t("home.footer.try") }}
      </router-link>
    </div>
    <div class="footer__bottom">
      <div class="footer__logo">
        <svg-vue icon="polygon-logo" class="w-5 h-5"/>


        <router-link :to="{ name: 'HomeWeb3infosmartcontracts' }">
          web3 info smart contracts
        </router-link>
<!--        <router-link class="btn__default" :to="{ name: 'HomeTactile'}">web3 info smart contracts</router-link>-->
<!--        <a href="https://polygonscan.com/address/0xf3a3d1B89A70E291531ECB4a1299117f5dE44612" target="_blank">-->
<!--          web3 info smart contracts-->
<!--        </a>-->
      </div>
      <span>
          © 2023 All Rights Reserved
      </span>
      <div class="lks">
        <router-link :to="{ name: 'HomeSupport' }">
          Support/FAQ
        </router-link>
        <router-link :to="{ name: 'HomeTermsofservice' }">
          Terms of service
        </router-link>
        <router-link :to="{ name: 'HomeAbout' }">
          About company
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss">
.Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  background: #1E2930;

  .footer__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 1024px;
    padding: 88px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(80px);
    color: #FFFFFF;
    //z-index: 1;
    border-radius: 32px;
    margin: 140px 0 140px 0;

    .footer__top-title {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {}
    }

    @media (max-width: 1100px) {
      width: calc(100% - 64px);
    }

    @media (max-width: 992px) {
      width: calc(100% - 64px);
    }

    .btn__default {
      background: #1E2930;
      color: #FFFFFF;
      padding: 12px 16px;
      font-size: 16px;
      text-transform: uppercase;
      border: 1px solid rgba(0, 0, 0, 0.4);
      border-radius: 100px;
      line-height: 20px;
    }

    &-title {
      margin-bottom: 88px;

      p {
        margin-bottom: 8px;
        font-size: 56px;
        line-height: 110%;
        letter-spacing: -0.04em;

        @media (max-width: 560px) {
          font-size: 32px;
        }
      }

      span {
        font-size: 24px;
        line-height: 130%;
        color: #FFFFFF;

        @media (max-width: 560px) {
          font-size: 16px;
        }
      }

      @media (max-width: 992px) {
        margin-bottom: 54px;
        font-size: 48px;
      }

      @media (max-width: 768px) {
        margin-bottom: 32px;
        font-size: 24px;
      }

      @media (max-width: 560px) {
        margin-bottom: 32px;
      }
    }

    @media (max-width: 992px) {
      max-width: 100%;
      //width: 100%;
      padding: 32px;
      text-align: center;
    }

    @media (max-width: 768px) {
      margin-bottom: 32px;
    }

    @media (max-width: 560px) {
      max-width: calc(100% - 32px);
    }
  }

  .footer__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 48px 32px;
    z-index: 1;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    .footer__logo {
      display: flex;
      align-items: center;

      img {
        max-width: 113px;
        width: 100%;
        margin-right: 12px;

        @media (max-width: 560px) {
          margin: 0 0 16px;
        }
      }

      @media (max-width: 992px) {
        margin: 96px 0 32px;
      }

      @media (max-width: 560px) {
        flex-direction: column;
        margin: 32px 0;
      }
    }

    a {
      font-size: 16px;
      line-height: 130%;
      color: rgba(255, 255, 255, 0.4);
    }

    span {
      font-size: 16px;
      line-height: 130%;
      margin-bottom: 20px;
      color: rgba(255, 255, 255, 0.4);

      @media (max-width: 560px) {
        order: 1;
        margin-top: 32px;
      }
    }

    .lks {
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-gap: 24px;
      font-size: 16px;
      line-height: 100%;
      text-transform: uppercase;

      a {
        line-height: 100%;
        color: rgba(255, 255, 255, 0.8);
        text-transform: uppercase;
      }

      @media (max-width: 992px) {
        grid-template-columns: none;
        grid-gap: 32px;
        text-align: center;

        span {
          grid-row: 3;
        }
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;
      position: relative;
      padding: 0 32px;
    }
  }

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //mask-image: url("/assets/icons/search.svg");
    background: url("~@/assets/images/home/bg-footer.jpg") center top/cover no-repeat;
    content: "";

    @media (max-width: 560px) {
      width: 100%;
      height: 70%;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
    height: auto;
    padding: 0 0 32px;
    background: #1E2930;
  }
}
</style>