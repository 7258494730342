<template>
  <div class="notifications">
    <div class="dropdown">
      <div class="title">
        <span>Notifications</span>
        <button priority="button" class="notificationBtn">Mark as read</button>
      </div>

      <div class="notifications-list">
        <a
          href="#"
          v-for="(item, idx) in notifications"
          :key="idx"
          :class="['notifications-list__item new']"
        >
          <div class="notifications-list__item-img">
            <BaseIcon :name="defineIcon(item.priority)" verAlign />
          </div>
          <div class="notifications-list__item-caption">
            <div class="notifications-list__item-title">
              {{ item.title }}
            </div>
            <div class="notifications-list__item-description">
              {{ item.text }}
            </div>
            <div class="notifications-list__item-time">12 min ago</div>
          </div>
        </a>
      </div>

    </div>
  </div>
</template>
<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'

export default {
  components: {
    BaseIcon
  },

  data () {
    return {
      notifications: [
        {
          title: 'News title',
          text: "We've just sent you a Cashback oucher worth 5USDT! Redeem it withi...",
          isRead: false,
          priority: 'default'
        },
        {
          title: 'Login attempted from new IP',
          text: 'The system has detected that your account is logged in from an...',
          isRead: true,
          priority: 'important',
          wasPublished: '12 min ago'
        },
        {
          title: 'New force sysem is availible',
          text: 'The system has detected that your account is logged in from an...',
          isRead: false,
          priority: 'updates',
          wasPublished: '12 min ago'
        },
        {
          title: 'Important attention',
          text: 'The system has detected that your account is logged in from an...',
          isRead: false,
          priority: 'attention',
          wasPublished: '12 min ago'
        },
        {
          title: 'Login attempted from new IP',
          text: 'The system has detected that your account is logged in from an...',
          isRead: false,
          priority: 'important',
          wasPublished: '12 min ago'
        }
      ]
    }
  },

  methods: {
    defineIcon (priority) {
      switch (priority) {
        case 'default':
          return 'notificationDefault'

        case 'important':
          return 'notificationImportant'

        case 'updates':
          return 'notificationUpdates'

        case 'attention':
          return 'notificationAttention'

        default:
          return 'notificationDefault'
      }
    },

    slicedText (text) {
      return `${text.substr(0, 65)}...`
    }
  }
}
</script>
