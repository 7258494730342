import { $api } from "@/plugins/Axios"

export default {
    state: {
        posts: [],
        newsOrAcademy: [],
        news: [],
    },
    getters: {
        allPosts(state) {
            return state.posts
        },
        allSpecificPosts(state) {
            return state.newsOrAcademy
        },
        allNewsPosts(state) {
            return state.news
        },
    },
    mutations: {
        updatePosts(state, posts) {
            state.posts = posts
        },
        updateSpecificPosts(state, posts) {
            state.newsOrAcademy = posts
        },
        updateNewsPosts(state, posts) {
            state.news = posts
        },
    },
    actions: {
        async fetchPosts(ctx, limit = 0) {
            let get = ""
            if (limit !== 0) {
                get += `?category_id=${limit}`
            }

            $api.get(`/posts${get}`).then((response) => {
                const posts = response.data.data
                ctx.commit("updatePosts", posts)
            })
        },

        async onlyNewfetchNewsPosts(ctx, payload = {}) {
            let get = ""

            if (Object.prototype.hasOwnProperty.call(payload, "limit")) {
                get += `?limit=${payload.limit}`
            }

            $api.get(`/posts/get-news${get}`).then((response) => {
                const news = response.data.data
                // if (Object.prototype.hasOwnProperty.call(payload, "onlyNews") && payload.onlyNews) {
                    ctx.commit("updateNewsPosts", news)
                // } else {
                //     ctx.commit("updateSpecificPosts", news)
                // }
            })
        },

        async fetchNewsPosts(ctx, payload = {limit: 10}) {
            let get = ""

            if (Object.prototype.hasOwnProperty.call(payload, "limit")) {
                get += `?limit=${payload.limit}`
            }

            $api.get(`/posts/get-news${get}`).then((response) => {
                const news = response.data.data
                // if (Object.prototype.hasOwnProperty.call(payload, "onlyNews") && payload.onlyNews) {
                //     ctx.commit("updateNewsPosts", news)
                // } else {
                    ctx.commit("updateSpecificPosts", news)
                // }
            })
        },

        async onlyNewfetchAcademyPosts(ctx, limit = 0) {
            let get = ""
            if (limit !== 0) {
                get += `?limit=${limit}`
            }

            $api.get(`/posts/get-academy${get}`).then((response) => {
                const news = response.data.data
                // if (limit) {
                    ctx.commit("updateNewsPosts", news)
                // } else {
                //     ctx.commit("updateSpecificPosts", news)
                // }
            })
        },

        async fetchAcademyPosts(ctx, limit = 10) {
            let get = ""
            if (limit !== 0) {
                get += `?limit=${limit}`
            }

            $api.get(`/posts/get-academy${get}`).then((response) => {
                const news = response.data.data
                // if (limit) {
                //     ctx.commit("updateNewsPosts", news)
                // } else {
                    ctx.commit("updateSpecificPosts", news)
                // }
            })
        },

        async fetchLandingNewsPosts(ctx, payload = {}) {
            let get = ""

            if (Object.prototype.hasOwnProperty.call(payload, "limit")) {
                get += `?limit=${payload.limit}`
            }

            $api.get(`/posts/get-news${get}`).then((response) => {
                ctx.commit("updateNewsPosts", response.data.data)
            })
        },

        async fetchLandingAcademyPosts(ctx, limit = 0) {
            let get = ""
            if (limit !== 0) {
                get += `?limit=${limit}`
            }

            $api.get(`/posts/get-academy${get}`).then((response) => {
                ctx.commit("updateSpecificPosts", response.data.data)
            })
        },

        async fetchPostsWithLimit(ctx, limit = 0) {
            let get = ""
            if (limit !== 0) {
                get += `?limit=${limit}`
            }

            $api.get(`/posts${get}`).then((response) => {
                const posts = response.data.data
                ctx.commit("updatePosts", posts)
            })
        },

        
    },
}
