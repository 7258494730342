<template>
  <header class="Header">
    <div class="logo">
      <router-link :to="{ name: 'landing' }">
        <svg-vue icon="logo" class="w-5 h-5"/>
      </router-link>
    </div>
    <div class="language btn__default mr light">
      <span>{{ $i18n.locale.toUpperCase() }}</span>
      <ul>
        <li
            @click="switchLocale(lang)"
            v-for="(lang, i) in langs"
            :key="`Lang${i}`"
            :value="lang"
            class="select__option"
            data-select="option"
            :data-value="lang"
            :data-index="i"
        >
          {{ lang.toUpperCase() }}
        </li>
      </ul>
    </div>
<!--    <router-link tag="a" class="btn__default light" :to="{ name: 'signup' }">-->
<!--      Login / Signup-->
<!--    </router-link>-->
    <a href="" @click="(e) => handleAuthClick('login', e)" class="btn__default mr light">
      {{ $t("landing.menu.login") }}
    </a>
    <a href="" @click="(e) => handleAuthClick('signup', e)" class="btn__default light">
      {{ $t("landing.menu.signup") }}
    </a>

    <ConnectModal />
  </header>
</template>

<script>
import {LANGS, LSTORAGE} from "@/config/constants";
import ConnectModal from "@/components/Layout/ConnectModal.vue";
import {getLocalStorageElement} from "@/helpers/localstorage";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {nullEmptyHash} from "@/helpers/matrix";
export default {
  name: "Header",
  data() {
    return {
      langs: LANGS,
      isOpenMenu: false,
      connectionType: null
    }
  },
  components: {
    ConnectModal
  },
  computed: {
    isUserConnected() {
      return this.isConnected || getLocalStorageElement(LSTORAGE.connected)
    },
    ...mapGetters("crypto", ["isConnected", "getParent", "getConnectionWallet"])
  },
  methods: {
    handleAuthClick(type, e) {
      e.preventDefault()
      this.connectionType = type
      this.setConnectionModal({ visible: true, type: "connect" })
    },
    async processAuth() {
      const [connectErr, connectRes] = await this.checkConnect()
      if (connectErr) this.$swal(connectErr)

      const hasNoParent = nullEmptyHash(this.getParent) === null

      if (this.connectionType === "signup") {
        // window.location.href = hasNoParent ? "/signup" : "/dashboard"
        await this.$router.push(hasNoParent ? {name: "signup" }: {name: "dashboard"})
      } else if (this.connectionType === "login") {
        if (!hasNoParent) {
          // window.location.href = "/dashboard"
          await this.$router.push({name: "dashboard"})
        } else {
          this.$swal({
            title: this.$t("connectModal.swal.title"),
            showCancelButton: true,
            confirmButtonColor: "#38c948",
            cancelButtonColor: "#828282",
            confirmButtonText: this.$t("connectModal.swal.confirmButtonText"),
            cancelButtonText: this.$t("connectModal.swal.cancelButtonText")
          }).then(async (result) => {
            if (result.isConfirmed) {
              // window.location.href = "/signup"
              await this.$router.push({name: "signup"})
            }
          })
        }
      }
    },
    toggle() {
      this.isOpenMenu = !this.isOpenMenu
      this.isOpenMenu ? document.body.classList.add("hidden") : document.body.classList.remove("hidden")
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        localStorage.setItem("lang", locale)
        this.$i18n.locale = locale
        this.$moment.locale(locale)
      }
    },
    ...mapMutations("ui", ["setConnectionModal"]),
    ...mapActions("crypto", ["checkConnect"])
  },
  // watch: {
  //   getConnectionWallet(newVal, oldVal) {
  //     if (newVal && newVal !== oldVal) {
  //       this.processAuth()
  //     }
  //   }
  // }
}
</script>

<style lang="scss">
.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 20px 32px;
  z-index: 888;

  .logo {
    display: flex;

    img {
      width: 55px;

      @media (max-width: 768px) {
        width: 35px;
      }
    }
  }

  .btn__default {
    font-family: "Suisse Int", sans-serif;
    font-weight: 400;
    line-height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #FFFFFF;
    border-radius: 50px;
    padding: 12px 16px;
    text-transform: uppercase;
    transition: .6s ease;

    &.mr {
      margin-right: 16px;
    }

    &.language {
      position: relative;
      margin-left: auto;
      cursor: pointer;

      ul {
        position: absolute;
        top: 80%;
        left: 0;
        width: 100%;
        padding: 12px 16px;
        opacity: 0;
        pointer-events: none;
        transition: .3s ease;

        li {
          a {
            color: #FFFFFF;
          }
        }

        li + li {
          margin-top: 8px;
        }
      }

      &:hover {
        ul {
          top: 100%;
          opacity: 1;
          pointer-events: auto;
          transition: .3s ease;
        }
      }
    }

    @media (max-width: 768px) {
      font-size: 12px;
      padding: 10px 16px;
    }
  }

  @media (max-width: 768px) {
    padding: 15px;
    background: rgba(0, 0, 0, 0.4);
  }
}
</style>