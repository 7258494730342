<template>
    <div class="footer-wrapper">
        <footer class="footer">
            <p class="footer__rights">{{ `© ${new Date().getFullYear()} ${$t("rights")}` }}</p>
            <button @click="setTermsModal({ visible: true })" class="footer__terms">{{ $t("terms") }}</button>
            <div class="footer__version">v{{appVersion}}</div>
        </footer>

        <UiModal :visible="termsModal.visible" modifier="wide" :title="$t('termsModal.title')" @closeModal="closeModal">
            <p class="text-justify">
                {{ $t("termsModal.content") }}
            </p>
            <button class="activation-modal__pay btn-reset glob-btn modal-btn" type="button" @click="closeModal">
                <span>{{ $t("termsModal.agree") }}</span>
            </button>
        </UiModal>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import BaseIcon from '@/components/UI/BaseIcon.vue'
export default {
    name: "Footer",
    components: { BaseIcon },
    data() {
        return {
            appVersion: process.env.VUE_APP_VERSION
        }
    },
    computed: {
        ...mapGetters("ui", ["termsModal"])
    },
    methods: {
        closeModal() {
            this.setTermsModal({ visible: false })
        },
        ...mapMutations("ui", ["setTermsModal"])
    }
}
</script>

<style lang="scss" scoped>
.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px;
    width: 100%;
    background: var(--color-black-800);
    border-radius: 10px;
    margin-top: 10px;
    &-wrapper {
        margin-top: auto;
    }
    p {
        margin-bottom: 0;
    }
    &-flags {
        display: flex;
        align-items: center;
    }
    &-flag {
        &:not(:last-child) {
            margin-right: 4px;
        }
    }

    &__terms {
        cursor: pointer;
        border: none;
        background: unset;
        border-bottom: solid 1px var(--color-white-50);
        transition: color 500ms;
        &:hover {
            color: var(--color-white-50);
        }
    }

    &__btn {
        background: unset;
        border: unset;
    }

    &__links {
        display: flex;
        gap: 10px;
    }

    .modal-btn {
        display: block;
    }
}

.text-justify {
    text-align: justify;
}

@media screen and (max-width: 960px) {
    .footer {
        flex-direction: column;

        &__rights {
            order: 4;
            margin-top: 10px;
        }

        &__links {
            order: 1;
        }

        &__terms {
            order: 2;
            margin-top: 10px;
        }

        &__version {
            order: 5;
            margin-top: 10px;
        }
    }
}
</style>
