import Web3 from 'web3'
import {Log, Warn, SentryLog} from '@/helpers/dev'
import axios from 'axios'
import {
  isEthereumWindow,
  getEthAccount,
  createProvider,
  switchNetwork,
  getLatestBlock,
  tokenFormatFromWei,
  increaseGas,
  getRevertReason,
} from '@/helpers/crypto'
import {
  isValidAccountService,
  getAuthMessageService,
  verifyAuthMessageService,
} from '@/api/user'
import {
  defineStructureIndexes,
  nullEmptyHash,
  countPartnersInLvl,
  countSpendInLvl,
  countPureRevenue,
  getClassicPriceByLevel,
} from '@/helpers/matrix'
import {watchLostTxs, saveTx} from '@/helpers/lostTx'
import {DICT, LSTORAGE} from '@/config/constants'
import {
  getLocalStorageElement,
  setLocalStorageElement,
} from '@/helpers/localstorage'
import '@/helpers/promises'

import mfsAbi from '@/api/mfs-abi.json'
import distributorAbi from '@/api/distributor-abi.json'
import mainAbi from '@/api/main-abi.json'
import sfcAbi from '@/api/sfc-abi.json'
import sfcNewAbi from '@/api/sfcnew-abi.json'
import nftAbi from '@/api/nft-abi.json'
import nftControlAbiOld from '@/api/nft-control-abi-old.json'
import nftRegistryAbiOld from '@/api/nft-registry-abi-old.json'
import nftControlAbi from '@/api/nft-control-abi.json'
import nftRegistryAbi from '@/api/nft-registry-abi.json'
import nftMigrationAbi from '@/api/nft-migration-abi.json'
import metacoreAbi from '@/api/metacore-abi.json'
import metapaymentAbi from '@/api/metapayment-abi.json'
import uvRegistryAbi from '@/api/uv-registry-abi.json'
import uvCoreAbi from '@/api/uv-core-abi.json'
import router from '@/router'
import {vm} from '@/main'

const emulateSlowTx = false

export default {
  state: {
    provider: null,
    connecting: null, // при первой инициализации dApp страниц (полная прогрузка)
    web3: {},
    connected: null, // критичная прогрузка от checkConnect
    account: null,
    remember_token: null,
    contracts: {
      main: null,
      sfc: null,
      sfcNew: null,
      sfc2: null,
      mfs: null,
      // @TODO delete next 2 rows after testing migration
      nftControlOld: null,
      nftRegistryOld: null,

      nftControl: null,
      nftRegistry: null,
      nftMigration: null,
      metacore: null,
      metapayment: null,
      uvRegistry: null,
      uvCore: null,

      royaltyNftsOld: [],
      royaltyNfts: [],
    },
    balance: {
      bnb: 0.0,
      sfc: 0.0,
      sfcNew: 0.0,
      sfc2: 0.0,
      busd: 0.0,
    },
    meta: {
      parent: null,
      nonce: 0,
      gasLimit: DICT.DEFAULT_GAS_LIMIT,
      gasPrice: DICT.DEFAULT_GAS_PRICE,
      maxFeePerGas: DICT.DEFAULT_GAS_PRICE,
    },
    connectionWallet: '',
  },
  getters: {
    isConnecting(state) {
      return state.connecting
    },
    getWeb3(state) {
      return state.web3
    },
    isActiveWallet(state) {
      return state.account !== null
    },
    isConnected(state) {
      return state.connected
    },
    getAccount(state) {
      return state.account
    },
    getToken(state) {
      return state.remember_token
    },
    getParent(state) {
      return state.meta.parent
    },
    getBalance(state) {
      return {
        bnb: state.balance.bnb.toFixed(4),
        sfc: state.balance.sfc.toFixed(2),
        sfcNew: state.balance.sfcNew.toFixed(2),
        sfc2: state.balance.sfc2.toFixed(2),
        busd: state.balance.busd.toFixed(2),
      }
    },
    getGas(state) {
      const isValidNumber = (n) => Number.isFinite(Number(n))

      return {
        limit: isValidNumber(state.meta.gasLimit)
          ? state.meta.gasLimit
          : DICT.DEFAULT_GAS_LIMIT,
        price: isValidNumber(state.meta.gasPrice)
          ? state.meta.gasPrice
          : DICT.DEFAULT_GAS_PRICE,
        max: isValidNumber(state.meta.maxFeePerGas)
          ? state.meta.maxFeePerGas
          : DICT.DEFAULT_GAS_MAX,
      }
    },
    getNonce(state) {
      return state.meta.nonce
    },
    getMinTransactionFee(state, getters) {
      const {limit, price} = getters.getGas
      return Number(price) * Number(limit)
    },
    getMainContract(state) {
      return state.contracts.main
    },
    getMFSContract(state) {
      return state.contracts.mfs
    },
    getSFCContract(state) {
      return state.contracts.sfc
    },
    getSFCNewContract(state) {
      return state.contracts.sfcNew
    },
    getSFC2Contract(state) {
      return state.contracts.sfc2
    },
    getNftControlOldContract(state) {
      return state.contracts.nftControlOld
    },
    getNftRegistryOldContract(state) {
      return state.contracts.nftRegistryOld
    },
    getNftControlContract(state) {
      return state.contracts.nftControl
    },
    getNftRegistryContract(state) {
      return state.contracts.nftRegistry
    },
    getNftMigrationContract(state) {
      return state.contracts.nftMigration
    },
    getNftLvl: (state) => (address) => {
      let oldNftLvl = state.contracts.royaltyNftsOld
        .map((x) => x.options.address.toLowerCase())
        .indexOf(address.toLowerCase())
      if (oldNftLvl !== -1) {
        return oldNftLvl
      }
      let nftLvl = state.contracts.royaltyNfts
        .map((x) => x.options.address.toLowerCase())
        .indexOf(address.toLowerCase())
      if (nftLvl !== -1) {
        return nftLvl
      }
      throw new Error(`Invalid nft address: ${address}`)
    },
    isAddressJustNFT: (state) => (address) => {
      console.log(
        'this.isJustNft xx',
        state.contracts.royaltyNftsOld[0].options.address,
        address
      )
      return (
        state.contracts.royaltyNftsOld[0]?.options.address.toLowerCase() ===
        address.toLowerCase() ||
        state.contracts.royaltyNfts[0]?.options.address.toLowerCase() ===
        address.toLowerCase()
      )
    },
    getLastNftAddress(state) {
      return state.contracts.royaltyNftsOld[7]?.options.address.toLowerCase();
    },
    getRoyaltyNFTVersion: (state) => (address) => {
      if (
        state.contracts.royaltyNftsOld
          .map((x) => x.options.address.toLowerCase())
          .indexOf(address.toLowerCase()) !== -1
      ) {
        return 1
      }
      if (
        state.contracts.royaltyNfts
          .map((x) => x.options.address.toLowerCase())
          .indexOf(address.toLowerCase()) !== -1
      ) {
        return 2
      }
      throw new Error(`Invalid nft address: ${address}`)
    },
    getMetaCore(state) {
      return state.contracts.metacore
    },
    getMetaPayment(state) {
      return state.contracts.metapayment
    },
    getUVRegistry(state) {
      return state.contracts.uvRegistry
    },
    getUVCore(state) {
      return state.contracts.uvCore
    },
    getEstimateParams(state) {
      return {
        from: state.account,
        // value: getters.getGas.price,
      }
    },
    getSendParams(state, getters) {
      return {
        nonce: getters.getNonce,
        from: state.account,
        gas: Number(getters.getGas.limit),
        type: '0x2',
        // gasPrice: getters.getGas.price,
        maxFeePerGas: emulateSlowTx ? '30000000001' : getters.getGas.max,
        maxPriorityFeePerGas: emulateSlowTx
          ? '30000000000'
          : getters.getGas.price,
      }
    },
    getConnectionWallet(state) {
      return state.connectionWallet
    },
  },
  mutations: {
    setConnecting(state, val) {
      state.connecting = val
    },
    setProvider(state, provider) {
      state.provider = provider
    },
    setWeb3Instance(state) {
      const web3Instance = new Web3(state.provider)
      web3Instance.eth.transactionBlockTimeout = emulateSlowTx ? 10 : 250
      web3Instance.eth.transactionConfirmationBlocks = 1

      state.web3 = web3Instance

      if (process.env.MODE === 'development') {
        window.Web3 = web3Instance
      }
    },
    setContract(state, {value, name}) {
      state.contracts = {
        ...state.contracts,
        [name]: value,
      }
    },
    setRoyaltyNftOldContract(state, {index, value}) {
      state.contracts.royaltyNftsOld[index] = value
    },
    setRoyaltyNftContract(state, {index, value}) {
      state.contracts.royaltyNfts[index] = value
    },
    setConnect(state, val) {
      state.connected = val

      setLocalStorageElement(LSTORAGE.connected, val)
    },
    setAccount(state, account) {
      state.account = account
      // Sentry.setUser({ id: account })
    },
    setToken(state, token) {
      state.remember_token = token

      setLocalStorageElement(LSTORAGE.token, token)
    },
    setBalance(state, {value, symbol}) {
      state.balance = {
        ...state.balance,
        [symbol]: value,
      }
    },
    setMeta(state, {value, name}) {
      Log(`set meta ${name} : ${value}`)
      state.meta = {
        ...state.meta,
        [name]: value,
      }
    },
    setConnectionWallet(state, {name, type = 'default'}) {
      setLocalStorageElement(LSTORAGE.wallet, name)
      const timeStamp = new Date().getTime()
      state.connectionWallet = `${name}:${type}:${timeStamp}`
    },

    resetState(state) {
      state.provider = null
      state.web3 = null
      state.connecting = null
      state.connected = null
      state.account = null
      state.remember_token = null
      state.contracts = {
        main: null,
        sfc: null,
        mfs: null,
        control: null,
        registry: null,
        metacore: null,
        metapayment: null,
        uvCore: null,
        uvRegistry: null,

        sfcNew: null,
        sfc2: null,
        // @TODO delete next 2 rows after testing migration
        nftControlOld: null,
        nftRegistryOld: null,

        nftControl: null,
        nftRegistry: null,
        nftMigration: null,

        royaltyNftsOld: [],
        royaltyNfts: [],
      }
      state.balance = {
        bnb: 0.0,
        sfc: 0.0,
        busd: 0.0,
      }
      state.meta = {
        parent: null,
        nonce: 0,
        gasLimit: DICT.DEFAULT_GAS_LIMIT,
        gasPrice: DICT.DEFAULT_GAS_PRICE,
        maxFeePerGas: DICT.DEFAULT_GAS_PRICE,
      }
    },
  },
  actions: {
    async init({dispatch}) {
      const lsConnected = getLocalStorageElement(LSTORAGE.connected)

      if (lsConnected === true) {
        const routerRequireWallet =
          router.currentRoute.meta && router.currentRoute.meta.requiresWallet

        if (routerRequireWallet) {
          dispatch('initApp')
          dispatch('initAuth')
        } else {
          dispatch('initAppMinimal')
        }

        watchLostTxs()
      }
    },

    async checkRegistration({dispatch, getters, state}, includeSaved) {
      try {
        let parent = null
        if (includeSaved) {
          parent = getters.getParent
        } else {
          parent = await getters.getMainContract.methods
            .parent(state.account)
            .call()
        }

        if (nullEmptyHash(parent) === null) {
          throw new Error(`${vm.$t('errors.registrationError')}`)
        }

        // если есть parent, проверить на наличие в базе
        // покрытие кейса с прерванной транзакцией после регистрации
        if (state.account) {
          const [accErr, accRes] = await isValidAccountService({
            hash: state.account,
          })

          if (accErr) {
            // TODO - как то еще отправлять транзакцию, но хеш операции .register не знаем
            // лучше делать на бекенде или тянуть api polygonscan
            await dispatch(
              'user/registerAccount',
              {
                account: state.account,
                parent: parent,
              },
              {root: true}
            )
          }
        }

        return [null, parent]
      } catch (err) {
        Warn('registrationCheck', err)
        return [err, null]
      }
    },

    // check window.ethereum exists, get user account, check provider chain, create contract instance
    async checkConnect({commit, dispatch, getters, state}, payload) {
      try {
        const [windowError, ethWindowName] = await isEthereumWindow()
        payload &&
        payload.onEthWindow &&
        payload.onEthWindow([windowError, ethWindowName])
        if (windowError) throw windowError

        const [providerError, provider] = await dispatch('checkProvider')
        if (providerError) throw providerError

        const [connectError, connected] = await dispatch('connectWeb3')
        if (connected === 'redispatch') {
          Log('WANTED REDISPATCH')
          await dispatch('checkProvider')
          await dispatch('connectWeb3')
        }

        payload &&
        payload.onNetwork &&
        payload.onNetwork([connectError, connected])
        if (connectError) throw connectError

        Log('at getEthAccount')
        const [accError, account] = await getEthAccount()
        // vm.$toast.info(account)
        if (accError) throw accError
        Log('after getEthAccount')

        // ШАГ 1. критичные данные загружены, пользователь имеет кошелек и подключен к правильной сети
        commit('setAccount', account)
        await dispatch('connectAppsContracts', account)
        if (getters.getParent === null) {
          const parent = await getters.getMainContract.methods
            .parent(account)
            .call()
          // vm.$toast.info(parent)
          commit('setMeta', {name: 'parent', value: parent})
        }
        // Шаг 2. Подключены контракты, пользователь может начать взаимодействие с сайтом
        commit('setConnect', true)
        return [null, account]
      } catch (err) {
        // vm.$toast.error(err.message)
        Warn('connect', err)
        commit('setConnect', false)
        return [err, null]
      }
    },

    async initApp({commit, dispatch, state}, payload) {
      // проблемы с лейаутом, mounted от layout вызывается всегда
      if (state.connecting === false) return

      commit('setConnecting', true)

      // как правило ставится один раз при подключении кошелька, не требует повторной установки для дальнейших действий
      if (payload && payload.name) {
        commit('setConnectionWallet', {name: payload.name})
      }

      const [connectError, account] = await dispatch('checkConnect')
      if (connectError) throw connectError
      payload && payload.onConnect && payload.onConnect()

      const [gasErr, gasRes] = await dispatch('getGas')
      if (gasErr) throw gasErr

      const [accErr, accRes] = await dispatch('getBalances', account)
      if (accErr) throw accErr

      // ШАГ 3. Получены данные по газу и информация пользователя
      // занимает много времени, не имеет высокой кртичности для отображения данных, но критично для выполнения транзакций

      commit('setConnecting', false)
    },

    async initAppMinimal({dispatch, commit}) {
      const [providerError, provider] = await dispatch('checkProvider')
      const [connectError, connected] = await dispatch('connectWeb3')
      const [accError, account] = await getEthAccount()
      account && commit('setAccount', account)
    },

    async initAuth({commit, state}) {
      const lsToken = getLocalStorageElement(LSTORAGE.token)
      if (lsToken) {
        commit('setToken', lsToken)
        return lsToken
      }

      const [err, message] = await getAuthMessageService()
      if (err) throw err

      let signed = true
      const signature = await state.web3.eth.personal
        .sign(message.message, state.account)
        .catch(() => (signed = false))

      if (!signature || !signed) {
        await vm.$swal
          .fire({
            confirmButtonText: vm.$t('confirm'),
            text: vm.$t('errors.auth'),
          })
          .then(async (result) => {
            await router.push({name: 'academy'})
            commit('resetState')
          })
      }

      const [authErr, auth] = await verifyAuthMessageService({
        address: state.account,
        signature: signature,
        message: message.message,
      })

      if (authErr) throw authErr

      commit('setToken', auth.remember_token)

      return auth.remember_token
    },

    async checkProvider({commit}) {
      try {
        const provider = createProvider()
        const providerName = getLocalStorageElement(LSTORAGE.wallet)

        commit('setProvider', provider)

        if (providerName === 'walletconnect' && provider.enable) {
          Log('enable?', !provider.connected)
          if (!provider.connected) {
            await provider.enable()
          }
        }

        return [null, provider]
      } catch (err) {
        Warn(err)
        return [err, null]
      }
    },

    async connectWeb3({commit}) {
      try {
        commit('setWeb3Instance')

        const [networkErr, networkRes] = await switchNetwork()
        if (networkErr) throw networkErr

        return [null, networkRes]
      } catch (err) {
        Warn(err)
        return [err, null]
      }
    },

    async connectAppsContracts({dispatch, commit}, account) {
      const contract = await dispatch('connectContract', {
        abi: mainAbi,
        contractAddress: DICT.CONTRACT_MAIN,
        account,
      })
      commit('setContract', {value: contract, name: 'main'})

      const mfsContract = await dispatch('connectContract', {
        abi: mfsAbi,
        contractAddress: DICT.CONTRACT_MFS,
      })
      commit('setContract', {value: mfsContract, name: 'mfs'})

      const sfcContract = await dispatch('connectContract', {
        abi: sfcAbi,
        contractAddress: DICT.CONTRACT_SFC,
      })
      commit('setContract', {value: sfcContract, name: 'sfc'})

      const sfcNewContract = await dispatch('connectContract', {
        abi: sfcNewAbi,
        contractAddress: DICT.CONTRACT_SFC_NEW,
      })
      commit('setContract', {value: sfcNewContract, name: 'sfcNew'})

      const sfc2Contract = await dispatch('connectContract', {
        abi: sfcAbi,
        contractAddress: DICT.CONTRACT_SFC2,
      })
      commit('setContract', {value: sfc2Contract, name: 'sfc2'})

      const nftControlOldContract = await dispatch('connectContract', {
        abi: nftControlAbiOld,
        contractAddress: DICT.CONTRACT_NFT_CONTROL_OLD,
      })
      commit('setContract', {
        value: nftControlOldContract,
        name: 'nftControlOld',
      })

      const nftRegistryOldContract = await dispatch('connectContract', {
        abi: nftRegistryAbiOld,
        contractAddress: DICT.CONTRACT_NFT_REGISTRY_OLD,
      })
      commit('setContract', {
        value: nftRegistryOldContract,
        name: 'nftRegistryOld',
      })

      const nftMigrationContract = await dispatch('connectContract', {
        abi: nftMigrationAbi,
        contractAddress: DICT.CONTRACT_NFT_MIGRATION,
      })
      commit('setContract', {
        value: nftMigrationContract,
        name: 'nftMigration',
      })

      // let nftOldPromises = [];
      // for (let i = 0; i < 8; i++) {
      //   nftOldPromises.push(() => {
      //     return (nftRegistryOldContract.methods.getForceNFT(i)).call();
      //   });
      // }

      // console.log('nftOldPromises', nftOldPromises)
      // const nftOldResult = await Promise.all(nftOldPromises);
      // console.log('nftOldResult', nftOldResult)

      // for (const i in nftOldResult) {
      for (let i = 0; i < 8; i++) {
        const contractAddress = await nftRegistryOldContract.methods
          .getForceNFT(i)
          .call()
        // console.log('xxx', i, nftOldResult[i])
        const nftContract = await dispatch('connectContract', {
          abi: nftAbi,
          contractAddress: contractAddress, // nftOldResult[i],
        })
        commit('setRoyaltyNftOldContract', {index: i, value: nftContract})
      }

      const nftControlContract = await dispatch('connectContract', {
        abi: nftControlAbi,
        contractAddress: DICT.CONTRACT_NFT_CONTROL,
      })
      commit('setContract', {value: nftControlContract, name: 'nftControl'})

      const nftRegistryContract = await dispatch('connectContract', {
        abi: nftRegistryAbi,
        contractAddress: DICT.CONTRACT_NFT_REGISTRY,
      })
      commit('setContract', {value: nftRegistryContract, name: 'nftRegistry'})

      // let nftPromises = [];
      // for (let i = 0; i < 8; i++) {
      //   nftPromises.push(() => {
      //     return nftRegistryContract.methods.getForceNFT(i).call();
      //   });
      // }
      // const nftResult = await Promise.all(nftPromises);
      // for (const i in nftResult) {

      for (let i = 0; i < 8; i++) {
        const contractAddress = await nftRegistryContract.methods
          .getForceNFT(i)
          .call()
        const nftContract = await dispatch('connectContract', {
          abi: nftAbi,
          // contractAddress: nftResult[i],
          contractAddress: contractAddress,
        })
        commit('setRoyaltyNftContract', {index: i, value: nftContract})
      }

      const metacoreContract = await dispatch('connectContract', {
        abi: metacoreAbi,
        contractAddress: DICT.CONTRACT_METACORE,
      })
      commit('setContract', {value: metacoreContract, name: 'metacore'})

      const metapaymentContract = await dispatch('connectContract', {
        abi: metapaymentAbi,
        contractAddress: DICT.CONTRACT_METAPAYMENT,
      })
      commit('setContract', {value: metapaymentContract, name: 'metapayment'})

      const uvRegistry = await dispatch('connectContract', {
        abi: uvRegistryAbi,
        contractAddress: DICT.CONTRACT_UV_REGISTRY,
      })
      commit('setContract', {value: uvRegistry, name: 'uvRegistry'})

      const uvCoreAddress = await uvRegistry.methods.getCoreContract().call()
      const uvCore = await dispatch('connectContract', {
        abi: uvCoreAbi,
        contractAddress: uvCoreAddress,
      })
      commit('setContract', {value: uvCore, name: 'uvCore'})
    },

    async connectContract({state}, {abi, contractAddress, account = null}) {
      try {
        return new state.web3.eth.Contract(abi, contractAddress, {
          from: account,
        })
      } catch (err) {
        Warn('contract', err)
        throw new Error('Error connecting contract')
      }
    },

    async getGas({commit, state}) {
      try {
        // get gas params - limit from latest block, price from eth utils
        const [blockErr, latestBlock] = await getLatestBlock('pending')
        if (blockErr) throw blockErr

        const gasPrice = await state.web3.eth.getGasPrice()
        const gasAggressive = Math.round(
          Number(gasPrice) * DICT.ESTIMATED_GAS_PRIORIY
        )

        commit('setMeta', {name: 'gasPrice', value: gasAggressive.toString()})

        if (latestBlock && latestBlock.baseFeePerGas) {
          Log('baseFeePerGas', latestBlock.baseFeePerGas)
          const maxFee =
            Math.round(
              DICT.ESTIMATE_GAS_MAX_PER_BASE * latestBlock.baseFeePerGas
            ) + gasAggressive
          commit('setMeta', {name: 'maxFeePerGas', value: maxFee.toString()})
        }

        // const gasLimit = Math.round(latestBlock.gasLimit / latestBlock.transactions.length).toString()
        // commit("setMeta", { name: "gasLimit", value: gasLimit })

        Log({gasPrice}, {gasAggressive: gasAggressive.toString()})

        return [null, true]
      } catch (err) {
        Warn('gas', err)
        return [err, null]
      }
    },

    async getBalances({dispatch, commit, state, getters}, account) {
      try {
        // bnb balance
        let balance = await state.web3.eth.getBalance(account)
        commit('setBalance', {
          symbol: 'bnb',
          value: tokenFormatFromWei(balance),
        })

        // mfs balance
        let balanceToken = await getters.getMFSContract.methods
          .balanceOf(account)
          .call()
        commit('setBalance', {
          symbol: 'busd',
          value: tokenFormatFromWei(balanceToken, 'ether'),
        })

        // sfc balance
        try {
          let balanceSfc = await getters.getSFCContract.methods
            .balanceOf(account)
            .call()
          commit('setBalance', {
            symbol: 'sfc',
            value: tokenFormatFromWei(balanceSfc),
          })
        } catch (e) {
          Log('fetch sfc balance error', e)
        }

        // sfc new balance
        try {
          let balanceSfcNew = await getters.getSFCNewContract.methods
            .balanceOf(account)
            .call()
          commit('setBalance', {
            symbol: 'sfcNew',
            value: tokenFormatFromWei(balanceSfcNew),
          })
        } catch (e) {
          Log('fetch sfc new balance error', e)
        }

        try {
          // sfc2 balance
          let balanceSfc2 = await getters.getSFC2Contract.methods
            .balanceOf(account)
            .call()
          commit('setBalance', {
            symbol: 'sfc2',
            value: tokenFormatFromWei(balanceSfc2),
          })
        } catch (e) {
          Log('fetch sfc2 balance error', e)
        }

        // const nonce = await dispatch("getNonce", account)
        await dispatch('getNonce', account)
        return [null, balance]
      } catch (err) {
        Warn('balances', err)
        return [err, null]
      }
    },

    async getNonce({state, commit}, account) {
      const nonce = await state.web3.eth.getTransactionCount(
        account || state.account
      )
      commit('setMeta', {name: 'nonce', value: Number(nonce)})

      return nonce
    },

    async getProgramLevels({dispatch, commit, getters, state}, account) {
      try {
        const tAccount = account || state.account
        const mainContract = getters.getMainContract

        // let levels = [...Array(12).keys()]

        // await Promise.all(
        //     levels.map(async (lvl) => {
        //         const result = await mainContract.methods.activate(tAccount, lvl).call()
        //         levels[lvl] = {
        //             lvl: lvl,
        //             active: result
        //         }
        //     })
        // )

        const lvls = await mainContract.methods.accountLevels(tAccount).call()

        let levels = lvls[0].map((x, index) => {
          return {
            lvl: index,
            active: x,
          }
        })

        Log('levels before gap', levels)

        lvls[1].forEach((x, index) => {
          if (x > 0 && levels[index].active === false) {
            levels[index].active = 'gap'
          }
        })

        // await Promise.all(
        //     levels.map(async (level) => {
        //         const method = getClassicTypeByLevel(level.lvl) === "s3" ? "matrixS3" : "matrixS6"
        //         let matrixResponce = await mainContract.methods[method](tAccount, level.lvl).call()
        //
        //         if (matrixResponce && +matrixResponce.slot > 0) {
        //             if (level.active === false) {
        //                 levels[level.lvl] = {
        //                     lvl: level.lvl,
        //                     active: "gap"
        //                 }
        //             }
        //         }
        //     })
        // )

        Log('levels after gap', levels)

        // check gaps for non-reactivation
        // const lastActive = levels ? levels.findLast((x) => x.active) : null
        let lastActive = null
        for (const lvl of levels) {
          if (lvl.active) {
            lastActive = lvl
          }
        }
        if (lastActive) {
          levels = levels.map((level) => {
            if (lastActive.lvl > level.lvl) {
              if (level.active === false) {
                return {
                  ...level,
                  active: 'gap',
                }
              }
            }

            return level
          })
        }

        return [null, levels]
      } catch (err) {
        Warn('getProgramLevels', err)
        SentryLog(err, 'levels')

        // return [new Error(`${vm.$t("matrix.levelError")}`), null]
        return [err, null]
      }
    },

    async createTransaction(
      {dispatch, getters, state},
      {func, onTransactionHash}
    ) {
      await dispatch('getGas')
      await dispatch('getNonce')

      let estimatedGas = await func.estimateGas({
        ...getters.getEstimateParams,
      })
      estimatedGas = increaseGas(estimatedGas)

      return func
        .send({
          ...getters.getSendParams,
          gas: estimatedGas,
        })
        .on('transactionHash', (hash) => {
          onTransactionHash && onTransactionHash(hash)
        })
    },

    async registerNewAccount(
      {dispatch, commit, getters, state},
      {account, parentAcc, onBlockchainPending}
    ) {
      try {
        const mainContract = getters.getMainContract

        let regHash
        const regRes = await dispatch('createTransaction', {
          // func: mainContract.methods.newRegistration(parentAcc),
          func: mainContract.methods.registration(parentAcc),
          onTransactionHash: (hash) => {
            regHash = hash
            onBlockchainPending && onBlockchainPending()
          },
        }).catch((e) => {
          if (e.message.includes('not mined within')) {
            const handle = setInterval(() => {
              state.web3.eth.getTransactionReceipt(regHash).then((resp) => {
                if (resp != null && resp.blockNumber > 0) {
                  clearInterval(handle)
                  dispatch(
                    'user/registerAccount',
                    {
                      account,
                      parent: parentAcc,
                    },
                    {root: true}
                  )
                }
              })
            }, 10000)
          } else {
            throw e
          }
        })

        const accResult = await dispatch(
          'user/registerAccount',
          {
            account,
            parent: parentAcc,
          },
          {root: true}
        )

        Log({regRes})

        await dispatch(
          'user/sendTransaction',
          {
            transactions: [
              {
                type: 'registration',
                transaction_hash: regRes.transactionHash,
                from: account,
                to: parentAcc,
              },
            ],
          },
          {root: true}
        ).catch(Warn)

        commit('setMeta', {name: 'parent', value: parentAcc})

        await dispatch('getBalances', account)

        return [null, accResult]
      } catch (err) {
        Warn('register', err)
        SentryLog(err, 'register')

        const errParsed = getRevertReason(err)
        return [errParsed, null]
      }
    },

    async verboseBuyGas(
      {state, getters, dispatch},
      {lvl, priceInEther, onAllowancePending, onBlockchainPending}
    ) {
      try {
        await dispatch('getGas')
        await dispatch('getNonce')

        let gasPrice = state.web3.utils.toBN(getters.getGas.price)
        const price = state.web3.utils.toWei(priceInEther.toString(), 'ether')

        let gasApprove = '0'
        let gasBuy = '0'

        await getters.getMFSContract.methods
          .approve(DICT.CONTRACT_MAIN, price)
          .estimateGas({...getters.getEstimateParams})
          .then((gasAmount) => {
            gasApprove = Math.round(gasAmount * DICT.ESTIMATED_GAS_INCREASE)
          })

        const allowance = await getters.getMFSContract.methods
          .allowance(getters.getAccount, DICT.CONTRACT_MAIN)
          .call()

        Log({allowance}, {price})

        if (Number(allowance) < Number(price)) {
          onAllowancePending && onAllowancePending()
          Log('send .approve', {
            ...getters.getSendParams,
            gas: gasApprove,
          })
          await getters.getMFSContract.methods
            .approve(DICT.CONTRACT_MAIN, price)
            .send({
              ...getters.getSendParams,
              gas: gasApprove,
            })
            .on('transactionHash', (hash) => {
              onBlockchainPending && onBlockchainPending()
            })
        }

        Log('estimate buy', {...getters.getEstimateParams})
        await getters.getMainContract.methods
          .buy(lvl)
          .estimateGas({...getters.getEstimateParams})
          .then((gasAmount) => {
            gasBuy = Math.round(gasAmount * DICT.ESTIMATED_GAS_INCREASE)
          })

        gasApprove = state.web3.utils.toBN(gasApprove.toString())
        gasBuy = state.web3.utils.toBN(gasBuy.toString())

        let totalGasPrice = gasPrice.mul(gasApprove.add(gasBuy))
        totalGasPrice = state.web3.utils.fromWei(
          totalGasPrice.toString(),
          'ether'
        )

        Log('totalGasPrice', totalGasPrice)

        return [null, totalGasPrice]
      } catch (err) {
        Warn('verboseBuyGas', err)
        SentryLog(err, 'buy')

        return [getRevertReason(err, 'Error estimating buying gas'), null]
      }
    },

    async buyLevel(
      {dispatch, commit, getters, state},
      {
        lvl,
        priceInEther,
        onAllowancePending,
        onTransactionPending,
        onBlockchainPending,
      }
    ) {
      const {account} = state
      const mainContract = getters.getMainContract
      const mfsContract = getters.getMFSContract
      await dispatch('getBalances', account)

      try {
        const price = state.web3.utils.toWei(priceInEther.toString(), 'ether')
        const curBalance = state.web3.utils.toWei(
          state.balance.busd.toString(),
          'ether'
        )
        const allowance = await mfsContract.methods
          .allowance(account, DICT.CONTRACT_MAIN)
          .call()
        // Log(`allowance - ${allowance}`, `price - ${price}`, "send params", getters.getSendParams)

        if (Number(allowance) < Number(price)) {
          if (Number(price) > Number(curBalance)) {
            // throw new Error(`Недостаточный баланс DAI. Необходимо - ${priceInEther} DAI`)
            vm.$swal(
              `${this.$t('matrix.buyLevel.insufficientFunds')}.
                             ${this.$t(
                'matrix.buyLevel.need'
              )} - ${priceInEther} DAI <br/>
                             <a href="/academy" target="_blank">Как пополнить</a>`
            )
            return
          }
        }

        // Approve passed, buy transaction
        let estimatedGas = state.meta.gasLimit
        await mainContract.methods
          .buy(lvl)
          .estimateGas({...getters.getEstimateParams})
          .then((gasAmount) => {
            estimatedGas = Math.round(gasAmount * DICT.ESTIMATED_GAS_INCREASE)
          })

        onTransactionPending && onTransactionPending()
        Log('send .buy', {
          ...getters.getSendParams,
          gas: estimatedGas,
        })

        let trHash = null
        const buyResult = await mainContract.methods
          .buy(lvl)
          .send({
            ...getters.getSendParams,
            gas: estimatedGas,
          })
          .on('transactionHash', (hash) => {
            trHash = hash
            Log({hash})
            onBlockchainPending && onBlockchainPending()
          })
          .on('confirmation', function (confirmationNumber, receipt) {
            Log('confirmation', confirmationNumber, receipt)
          })
          .on('receipt', function (receipt) {
            Log('receipt', receipt)
          })
          .catch((e) => {
            if (e.message.includes('not mined within')) {
              saveTx({
                tx: trHash,
                action: 'buy',
                params: {account, lvl: lvl},
              })
              const error = Error(vm.$t('lostTxs.buyWait'))
              error.status = 202
              throw error
            } else {
              throw e
            }
          })

        Log('.buy await compleate')
        // const [err1, levels1] = await dispatch("getProgramLevels")
        // Log("1lvls", levels1)

        commit(
          'user/setClassMatrixLevel',
          {
            lvl: lvl,
            active: true,
          },
          {root: true}
        )

        await dispatch('sendBuyTransaction', buyResult).catch(Warn)

        // save on backend
        await dispatch(
          'user/setLevel',
          {
            account: account,
            level: lvl + 1, // backend starts with 1
          },
          {root: true}
        )

        await dispatch('getBalances', account)

        // const [err2, levels2] = await dispatch("getProgramLevels")
        // Log("2lvls", levels2)

        return [null, buyResult]
      } catch (err) {
        Warn('buy', err)
        SentryLog(err, 'buy')
        return [getRevertReason(err, 'Error, contact administrator'), null]
      }
    },

    async sendBuyTransaction({dispatch}, buyResult) {
      Log({buyResult})
      if (!buyResult) return

      const txHash = buyResult.transactionHash
      const eventNames = Object.keys(buyResult.events).filter((key) =>
        [
          'simpleBuy',
          'newSlot',
          'upgrade',
          'updateOtherPersonStructure',
        ].includes(key)
      )

      const transactions = eventNames.map((key) => {
        const event = buyResult.events[key]

        let lvlName = event.returnValues.lvl !== undefined ? 'lvl' : 'newLvl'

        return {
          type: key,
          transaction_hash: txHash,
          from: event.returnValues.buyer,
          to: event.returnValues.receiver,
          price: getClassicPriceByLevel(+event.returnValues.lvl),
          lvl: +event.returnValues[lvlName] + 1,
        }
      })

      Log('all events: ', Object.keys(buyResult.events))

      await dispatch(
        'user/sendTransaction',
        {transactions},
        {root: true}
      ).catch((err) => {
        SentryLog(err, 'send transaction')
      })
    },

    async requestStructure(
      {dispatch, commit, getters, state},
      {
        account,
        level,
        type,
        slot: slotProp,
        previousActiveSlot,
        fetchUser,
        countRevenue,
      }
    ) {
      try {
        let structure = {
          slot: null,
          totalSlots: '0',
          totalPartners: 0,
          totalFrozen: 0,
          totalSpend: 0,
          pureRevenue: 0,
          pureRevenueCycle: 0,
          autoRecycle: null,
          autoUpgrade: null,
          lvl1: [null, null],
          lvl2: [...Array(4)].map((_) => null),
          lvl3: [...Array(8)].map((_) => null),
        }
        const contract = getters.getMainContract

        // получаем slot - индекс
        const method = type === 's3' ? 'matrixS3' : 'matrixS6'
        let matrixResponce = await contract.methods[method](
          account,
          level.toString()
        ).call()
        Log(matrixResponce, account, level.toString())

        // ставим мета параметры и делаем просчеты обьектов
        structure.totalSlots = matrixResponce.slot
        const {partners, frozen} = countPartnersInLvl(type, matrixResponce)
        structure.totalPartners = partners
        structure.totalFrozen = state.web3.utils.fromWei(frozen, 'ether')
        structure.totalSpend = countSpendInLvl(partners, level)

        let {slot} = matrixResponce
        if (slotProp !== undefined) {
          slot = slotProp.toString()
        }
        if (previousActiveSlot) {
          if (+slot > 0) {
            slot = +slot - 1
          }
        }
        structure.slot = slot

        // определяем индексы
        const {indexesS3, indexesS6Lvl1, indexesS6Lvl2} =
          defineStructureIndexes({type, slot})
        // Log(indexesS3, indexesS6Lvl1, indexesS6Lvl2)

        // настройки рецикл / автоапгрейд
        const settings = await contract.methods
          .getSettings(account, level.toString())
          .call()
        Log({settings})

        if (settings) {
          structure.autoRecycle = settings['0']
          structure.autoUpgrade = settings['1']
        }

        // запрашиваем по индексам
        const shouldRequestLvl1 =
          countPartnersInLvl(type, matrixResponce).partners > 0
        let shouldRequestLvl2 = shouldRequestLvl1

        if (type === 's3') {
          if (shouldRequestLvl1) {
            await Promise.all(
              indexesS3.map(async (index, idx) => {
                const childsS3Res = await contract.methods
                  .childsS3(account, level, index)
                  .call()
                  .catch((err) => {
                    Warn(err)
                    console.log('ERRRRRRRRRRR', account, level, index, err)
                  })
                structure.lvl1[idx] = nullEmptyHash(childsS3Res)
              })
            )
          }
        } else if (type === 's6') {
          if (shouldRequestLvl1) {
            await Promise.all(
              indexesS6Lvl1.map(async (index, idx) => {
                const childsS6Lvl1 = await contract.methods
                  .childsS6Lvl1(account, level, index)
                  .call()
                  .catch((err) => {
                    Warn(err)
                  })
                // Log(idx, { childsS6Lvl1 })
                structure.lvl1[idx] = nullEmptyHash(childsS6Lvl1)
              })
            )
          }

          shouldRequestLvl2 = structure.lvl1.some((x) => x !== null)

          if (shouldRequestLvl2) {
            await Promise.all(
              indexesS6Lvl2.map(async (index, idx) => {
                const childsS6Lvl2 = await contract.methods
                  .childsS6Lvl2(account, level, index)
                  .call()
                  .catch((err) => {
                    Warn(err)
                  })
                structure.lvl2[idx] = nullEmptyHash(childsS6Lvl2)
              })
            )
          }
        }

        // count total revenue only once
        structure.pureRevenue = countRevenue
          ? countRevenue
          : countPureRevenue({...structure, level})
        structure.pureRevenueCycle = countPureRevenue({
          ...structure,
          level,
          forCurrentSlot: true,
        })

        // fetch users by address
        if (fetchUser) {
          // const [err, users] = await getUsersBatchService({ accounts: structure.lvl1 })
          // console.log({ users })

          await Promise.all(
            structure.lvl1.map(async (address, idx) => {
              if (address) {
                const res = await dispatch(
                  'user/getUserByField',
                  {
                    account: address,
                  },
                  {root: true}
                ).catch(Warn)

                if (res && res.users) {
                  structure.lvl1[idx] = res.users
                }
              }
            })
          )
          await Promise.all(
            structure.lvl2.map(async (address, idx) => {
              if (address) {
                const res = await dispatch(
                  'user/getUserByField',
                  {
                    account: address,
                  },
                  {root: true}
                ).catch(Warn)

                if (res && res.users) {
                  structure.lvl2[idx] = res.users
                }
              }
            })
          )
        }

        return [null, structure]
      } catch (err) {
        Warn(err)
        SentryLog(err, 'structure')

        return [err, null]
      }
    },

    async requestTree({state, getters}, {account, onNext}) {
      try {
        let tree = []
        const contract = getters.getMainContract

        let shouldGo = true
        let index = 0

        while (shouldGo) {
          const child = await contract.methods
            .childs(account, index.toString())
            .call()
            .catch((err) => {
              shouldGo = false
            })

          if (child) {
            index++
            tree.push({idx: index, account: child})
            onNext && onNext(tree)
          }
        }

        return [null, tree]
      } catch (err) {
        Warn(err)
        return [err, null]
      }
    },

    async withdrawFrozen({dispatch, getters, state}, {lvl, type}) {
      try {
        const contract = getters.getMainContract
        const method = type === 's3' ? 'withdrawS3' : 'withdrawS6'

        await dispatch('createTransaction', {
          func: contract.methods[method](lvl),
        })

        return [null, true]
      } catch (err) {
        Warn(err)
        SentryLog(err, 'frozen')
        return [getRevertReason(err), null]
      }
    },

    async changeAutoReCycle({dispatch, getters, state}, {flag, lvl}) {
      try {
        const contract = getters.getMainContract

        await dispatch('createTransaction', {
          func: contract.methods.changeAutoReCycle(lvl),
        })

        return [null, true]
      } catch (err) {
        Warn(err)
        SentryLog(err, 'AutoRecycle')
        return [getRevertReason(err), null]
      }
    },

    async changeAutoUpgrade({dispatch, getters, state}, {flag, lvl}) {
      try {
        const contract = getters.getMainContract

        await dispatch('createTransaction', {
          func: contract.methods.changeAutoUpgrade(lvl),
        })

        return [null, true]
      } catch (err) {
        Warn(err)
        SentryLog(err, 'AutoUpgrade')
        return [getRevertReason(err), null]
      }
    },

    async transferAccount({dispatch, getters, state}, {to}) {
      try {
        const contract = getters.getMainContract

        await dispatch('createTransaction', {
          func: contract.methods.givePermission(DICT.CREATOR),
          // onTransactionHash: (hash) => {
          //     console.log({ hash })
          // },
        })
        await dispatch('createTransaction', {
          func: contract.methods.changeAddress(to),
        })

        return [null, true]
      } catch (err) {
        Warn(err)
        SentryLog(err, 'Transfer')
        return [getRevertReason(err), null]
      }
    },

    async logOut({commit, state}) {
      const {provider} = state
      const providerName = getLocalStorageElement(LSTORAGE.wallet)

      localStorage.removeItem(LSTORAGE.connected)
      localStorage.removeItem(LSTORAGE.wallet)
      localStorage.removeItem(LSTORAGE.walletconnect)
      localStorage.removeItem(LSTORAGE.token)

      if (providerName === 'walletconnect' && provider.enable) {
        if (provider.connected && provider.disconnect) {
          await provider.disconnect()
        }
      }

      commit('resetState')

      if (router.currentRoute.name !== 'academy') {
        await router.push({name: 'academy'})
      }
    },

    async checkWithdraw({state}, address) {
      try {
        const distributor = new state.web3.eth.Contract(
          distributorAbi,
          '0xb50dDC3b3CbCDfBc1a3fE8235ffE73a3cD75C59B'
        )
        return await distributor.methods.isClaimed(address).call()
      } catch (error) {
        console.log(error)
      }
    },

    async withdrawMoney({state}, data) {
      try {
        const distributor = new state.web3.eth.Contract(
          distributorAbi,
          '0xb50dDC3b3CbCDfBc1a3fE8235ffE73a3cD75C59B',
          {from: data.address}
        )
        const gas = await state.web3.eth.getGasPrice().then((gas) => gas)
        await distributor.methods
          .claim(data.index, data.address, data.amount, data.merkleProof)
          .send({from: data.address, value: 0, gasPrice: gas})
          .on('receipt', () => {
            axios.post('https://web3up.net/api/set-withdraw', {
              address: data.address,
              sum: data.sum,
            })
          })
      } catch (error) {
        console.log(error)
      }
    },

    // Royalty methods
    async getJustNFT({getters, state}, {onBlockchainPending}) {
      try {
        const gasPrice = await state.web3.eth.getGasPrice()

        let txHash
        const res = await getters.getNftControlContract.methods
          .getEmptyNFT()
          .send({
            from: state.account,
            gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
          })
          .on('transactionHash', (hash) => {
            txHash = hash
            Log({hash})
            onBlockchainPending && onBlockchainPending()
          })
          .on('confirmation', function (confirmationNumber, receipt) {
            Log('confirmation', confirmationNumber, receipt)
          })
          .on('receipt', function (receipt) {
            Log('receipt', receipt)
          })
          .catch((e) => {
            if (e.message.includes('not mined within')) {
              const error = Error(vm.$t('lostTxs.buyWait'))
              error.status = 202
              throw error
            } else {
              throw e
            }
          })

        Log('getJustNFT res: ', res)
        Log('getJustNFT txHash: ', txHash)
        return [null, null]
      } catch (err) {
        Warn('getJustNFT', err)
        SentryLog(err, 'getJustNFT')

        const errParsed = getRevertReason(err)
        return [errParsed, null]
      }
    },

    async approve(
      {getters, state},
      {contract, spender, amount, onAllowancePending}
    ) {
      const alreadyApproved = await contract.methods.allowance(
        state.account,
        spender
      ).call();

      const alreadyApprovedBN = state.web3.utils.toBN(alreadyApproved);
      const amountBM = state.web3.utils.toBN(amount);

      if (amountBM.gt(alreadyApprovedBN)) {
        const gasPrice = await state.web3.eth.getGasPrice()
        const needToApprove = amountBM.sub(alreadyApprovedBN);
        await contract.methods
          .approve(spender, needToApprove)
          .send({
            ...getters.getSendParams,
            gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
          })
          .on('transactionHash', (hash) => {
            Log({hash})
            onAllowancePending && onAllowancePending()
          })
      }
    },

    async levelUpJustNFTBySFC(
      {dispatch, getters, state},
      {
        tokenId,
        sfcAmount,
        sfc2Amount,
        onAllowancePending,
        onBlockchainPending,
      }
    ) {
      try {
        const zero = state.web3.utils.toBN(0)
        // await dispatch('getNonce');
        let sfcPrice = state.web3.utils.toWei(state.web3.utils.toBN(sfcAmount.toString()), 'ether'),
          sfc2Price = state.web3.utils.toWei(state.web3.utils.toBN(sfc2Amount.toString()), 'ether'),
          amounts = [sfcPrice, sfc2Price, 0]
        if (!sfcAmount && !sfc2Amount) {
          return ['Invalid SFC amount', null]
        }

        console.log('getters.getSFCNewContract', getters.getSFCNewContract._address, DICT.CONTRACT_METAPAYMENT)

        if (sfcPrice.gt(zero)) {
          await dispatch('approve', {
            contract: getters.getSFCNewContract,
            spender: DICT.CONTRACT_METAPAYMENT,
            amount: sfcPrice,
            onAllowancePending: onAllowancePending,
          })
        }

        console.log('getters.getSFCNewContract', getters.getSFC2Contract._address, DICT.CONTRACT_METAPAYMENT)
        if (sfc2Price.gt(zero)) {
          await dispatch('approve', {
            contract: getters.getSFC2Contract,
            spender: DICT.CONTRACT_METAPAYMENT,
            amount: sfc2Price,
            onAllowancePending: onAllowancePending,
          })
        }

        const estimatedGas = await getters.getNftControlContract.methods
          .levelUpEmptyNFT(tokenId, amounts)
          .estimateGas({...getters.getEstimateParams})

        // await dispatch("getNonce")
        let txHash
        const res = await getters.getNftControlContract.methods
          .levelUpEmptyNFT(tokenId, amounts)
          .send({
            ...getters.getSendParams,
            gas: estimatedGas * 2,
          })
          .on('transactionHash', (hash) => {
            txHash = hash
            Log({hash})
            onBlockchainPending && onBlockchainPending()
          })
          .catch((e) => {
            if (e.message.includes('not mined within')) {
              const error = Error(vm.$t('lostTxs.buyWait'))
              error.status = 202
              throw error
            } else {
              throw e
            }
          })

        await dispatch('getNonce')
        Log('just nft lvl up: ', res)
        Log('just nft lvl up txHash: ', txHash)
        return [null, null]
      } catch (err) {
        Warn('getJustNFT', err)
        SentryLog(err, 'getJustNFT')

        const errParsed = getRevertReason(err)
        return [errParsed, null]
      }
    },
    async mergeNfts({getters, state}, {level, ids, onBlockchainPending}) {
      try {
        const gasPrice = await state.web3.eth.getGasPrice()
        let txHash
        const res = await getters.getNftControlContract.methods
          .mergeNFT(level, ids)
          .send({
            from: state.account,
            gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
            // ...getters.getSendParams
            gas: 2000000,
          })
          .on('transactionHash', (hash) => {
            txHash = hash
            Log({hash})
            onBlockchainPending && onBlockchainPending()
          })
          .on('confirmation', function (confirmationNumber, receipt) {
            Log('confirmation', confirmationNumber, receipt)
          })
          .on('receipt', function (receipt) {
            Log('receipt', receipt)
          })
          .catch((e) => {
            if (e.message.includes('not mined within')) {
              const error = Error(vm.$t('lostTxs.buyWait'))
              error.status = 202
              throw error
            } else {
              throw e
            }
          })

        Log('merge nfts: ', res)
        Log('merge nfts hash: ', txHash)
        return [null, null]
      } catch (err) {
        Warn('getJustNFT', err)
        SentryLog(err, 'getJustNFT')

        const errParsed = getRevertReason(err)
        return [errParsed, null]
      }
    },

    async migrateRoyaltyNFT(
      {getters, state},
      {level, tokenId, onBlockchainPending}
    ) {
      try {
        console.log('getNftMigrationContract', level, tokenId)
        const gasPrice = await state.web3.eth.getGasPrice()
        await getters.getNftMigrationContract.methods
          .migrate(level, tokenId)
          .send({
            from: state.account,
            gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
          })
          .on('transactionHash', (hash) => {
            Log({hash})
            onBlockchainPending && onBlockchainPending()
          })
          .on('confirmation', function (confirmationNumber, receipt) {
            Log('confirmation', confirmationNumber, receipt)
          })
          .on('receipt', function (receipt) {
            Log('receipt', receipt)
          })
          .catch((e) => {
            throw e
          })
      } catch (err) {
        Warn('setDirectPaymentStatus ', err)
        SentryLog(err, 'setDirectPaymentStatus')
        throw new getRevertReason(err)
      }
    },

    async migrateAllRoyaltyNFTs({getters, state}, {onBlockchainPending}) {
      try {
        await getters.getNftMigrationContract.methods
          .migrateAll()
          .send({
            from: state.account,
          })
          .on('transactionHash', (hash) => {
            Log({hash})
            onBlockchainPending && onBlockchainPending()
          })
          .on('confirmation', function (confirmationNumber, receipt) {
            Log('confirmation', confirmationNumber, receipt)
          })
          .on('receipt', function (receipt) {
            Log('receipt', receipt)
          })
          .catch((e) => {
            throw e
          })
      } catch (err) {
        Warn('setDirectPaymentStatus ', err)
        SentryLog(err, 'setDirectPaymentStatus')
        throw new getRevertReason(err)
      }
    },

    async swapSfcNew({getters, state}, {onBlockchainPending}) {
      try {
        const gasPrice = await state.web3.eth.getGasPrice()
        let txHash
        const res = await getters.getSFCNewContract.methods
          .mint(state.account, 100) // amount will autocalculate by smartcontract
          .send({
            from: state.account,
            gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
            // ...getters.getSendParams
          })
          .on('transactionHash', (hash) => {
            txHash = hash
            Log({hash})
            onBlockchainPending && onBlockchainPending()
          })
          .on('confirmation', function (confirmationNumber, receipt) {
            Log('confirmation', confirmationNumber, receipt)
          })
          .on('receipt', function (receipt) {
            Log('receipt', receipt)
          })
          .catch((e) => {
            if (e.message.includes('not mined within')) {
              const error = Error(vm.$t('lostTxs.buyWait'))
              error.status = 202
              throw error
            } else {
              throw e
            }
          })

        Log('swapSfc1: ', res)
        Log('swapSfc1 hash: ', txHash)
        return [null, null]
      } catch (err) {
        Warn('swapSfc1', err)
        SentryLog(err, 'swapSfc1')

        const errParsed = getRevertReason(err)
        return [errParsed, null]
      }
    },

    async alreadyReceivedEmptyNFTInOldControl({getters, dispatch}, {address}) {
      try {
        return await getters.getNftControlOldContract.methods
          .alreadyReceivedEmptyNFT(address)
          .call()
      } catch (e) {
        return false
      }
    },

    async alreadyReceivedEmptyNFT({getters, dispatch}, {address}) {
      try {
        // @TODO move to cache
        const userId = await dispatch('getMetaCoreId', address)
        return await getters.getNftControlContract.methods
          .alreadyReceivedEmptyNFT(userId)
          .call()
      } catch (e) {
        return false
      }
    },

    async alreadySwappedSFC({getters}, address) {
      return await getters.getSFCNewContract.methods.minted(address).call()
    },

    async signMessage({state}, message) {
      return await state.web3.eth.personal.sign(message, state.account)
    },

    async isRegisteredInMeraCore({getters}, account) {
      return (await getters.getMetaCore.methods.getUserId(account).call()) > 0
    },

    async getMetaCoreId({getters}, account) {
      return await getters.getMetaCore.methods.getUserId(account).call()
    },

    async checkMetaCoreRegistration(
      {dispatch, getters, state},
      {onBlockchainPending}
    ) {
      if (await dispatch('isRegisteredInMeraCore', state.account)) {
        // if (await getters.isRegisteredInMeraCore(state.account)) {
        return true
      }
      const gasPrice = await state.web3.eth.getGasPrice()

      await getters.getMainContract.methods
        .migrateToCore()
        .send({
          from: state.account,
          gasPrice: Math.round(gasPrice * DICT.ESTIMATED_GAS_PRIORIY),
        })
        .on('transactionHash', (hash) => {
          Log({hash})
          onBlockchainPending && onBlockchainPending()
        })
        .on('confirmation', function (confirmationNumber, receipt) {
          Log('confirmation', confirmationNumber, receipt)
        })
        .on('receipt', function (receipt) {
          Log('receipt', receipt)
        })
        .catch((e) => {
          throw e
        })
    },

    async getMetaPaymentBalancesFormatted({getters, dispatch}, account) {
      const userId = await dispatch('getMetaCoreId', account)
      if (userId === 0) {
        throw new Error('Account does not registered in meta core')
      }
      const tokens = [
        DICT.CONTRACT_MFS,
        DICT.CONTRACT_FORCECOIN,
        DICT.CONTRACT_ENERGYCOIN,
      ]

      const promises = []
      for (let token of tokens) {
        promises.push(
          tokenFormatFromWei(
            await getters.getMetaPayment.methods
              .getBalance(token, userId)
              .call()
          )
        )
      }
      return await Promise.all(promises)
    },

    async getMetaPaymentReservedAddress({getters, dispatch}, account) {
      const userId = await dispatch('getMetaCoreId', account)
      const reservedAddress = await getters.getMetaPayment.methods
        .getReservedAddress(userId)
        .call()
      return reservedAddress === '0x0000000000000000000000000000000000000000'
        ? null
        : reservedAddress
    },

    async getMetaPaymentDirectPaymentStatus({getters, dispatch}, account) {
      const userId = await dispatch('getMetaCoreId', account)
      return await getters.getMetaPayment.methods
        .getDirectPaymentStatus(userId)
        .call()
    },

    async setMetaPaymentDirectPaymentStatus(
      {getters, state},
      {status, onBlockchainPending}
    ) {
      try {
        await getters.getMetaPayment.methods
          .setDirectPayment(status)
          .send({
            from: state.account,
          })
          .on('transactionHash', (hash) => {
            Log({hash})
            onBlockchainPending && onBlockchainPending()
          })
          .on('confirmation', function (confirmationNumber, receipt) {
            Log('confirmation', confirmationNumber, receipt)
          })
          .on('receipt', function (receipt) {
            Log('receipt', receipt)
          })
          .catch((e) => {
            if (e.message.includes('not mined within')) {
              // saveTx({
              //     tx: txHash,
              //     action: "buy",
              //     params: { account, lvl: lvl }
              // })
              const error = Error(vm.$t('lostTxs.buyWait'))
              error.status = 202
              throw error
            } else {
              throw e
            }
          })

        return status
      } catch (err) {
        Warn('setDirectPaymentStatus ', err)
        SentryLog(err, 'setDirectPaymentStatus')
        throw new getRevertReason(err)
      }
    },
    async getClassicLevel({getters, dispatch}, account) {
      const userId = await dispatch('getMetaCoreId', account)
      return await getters.getMainContract.methods.getLevelForNFT(userId).call()
    },
    async getUVLevel({getters, dispatch}, account) {
      const userId = await dispatch('getMetaCoreId', account)
      return await getters.getUVCore.methods.getLevelForNFT(userId).call()
    },
  },
  namespaced: true,
}
