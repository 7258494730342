<template>
  <div class="sidebar__bottom" v-show="isActiveWallet">
    <div v-if="isClosed">
      <div class="sidebar__closedImg" @click.stop="isOpen = !isOpen">
          <UiAvatar 
            :user="currentUser" 
            alt="user logo"
            class="sidebar__closedImg-ico"
          />
      </div>

      <div class="sidebar__closedBtn">
        <BaseIcon
            name="closedIconCopy"
            @event="handleClipboardCopy"
        />
      </div>
    </div>
    <div v-if="!isClosed" class="sidebar__force-id" @click.stop="isOpen = !isOpen">
      <div class="sidebar__force-id-ico">
          <UiAvatar 
            :user="currentUser" 
            alt="user logo"
            class="sidebar__closedImg-ico"
          />
      </div>
      <div class="sidebar__force-id-text">
        {{ currentUser?.name || 'Force ID' }}
      </div>
      <div class="sidebar__force-id-btn-inner">
        <BaseIcon name="arrowRight" />
      </div>
    </div>
    <ButtonLight v-if="!isClosed" :text="$t('menu.copyLink')" @event="handleClipboardCopy"/>

    <SocialLinks v-if="!isClosed" />
    <div v-show="isOpen">
      <ul :class="[isClosed ? 'sidebar__bottom-popupClosed' : 'sidebar__bottom-popup']">
        <li  class="sidebar__bottom-search" @click.stop="openSearch">
          <BaseIcon
            class="sidebar__bottom--mr"
            name="search"
            verAlign
            @event="openSearch"
          /><span v-if="!isClosed">{{ $t('sidebarBottom.search') }}</span>
        </li>
        <li class="sidebar__bottom-logout" @click.stop="logout">
          <BaseIcon
            class="sidebar__bottom--mr"
            name="logOut"
            verAlign
            @event="logout"
          /><span v-if="!isClosed">{{ $t('sidebarBottom.logout') }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import ButtonLight from '@/components/UI/ButtonLight.vue'
import SocialLinks from '@/components/UI/SocialLinks.vue'
import { copyToClipboard } from '@/helpers/clipboard'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    BaseIcon,
    ButtonLight,
    SocialLinks,
  },
  props: {
    isClosed: {
      type: Boolean
    }
  },

  data() {
    return {
      isOpen: false,
    }
  },
  
  computed: {
    ...mapState('user', ['currentUser']),
    ...mapGetters("crypto", ["isActiveWallet"]),

  refLink() {
      return `https://${window.location.host}/r/${this.currentUser.name}`
    },
  },

  methods: {
    ...mapActions('user', ['saveUser', 'clearUser']),

    handleClipboardCopy() {
      if (!this.currentUser) return
      // navigator.clipboard.writeText(this.refLink)
      copyToClipboard(this.refLink).then(() => {
          this.$toast.success(`${this.refLink} ${this.$t('account.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('account.copyError')}`)
        })
    },

    openSearch() {
      this.$emit('isSearch')
      this.isOpen = false
    },
    
    logout() {
      this.$emit('logout')
      this.isOpen = false
    },
  },

  mounted() {
    this.saveUser(),

    window.addEventListener('click', (event) => {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false
      }
    })
  },
  unmounted() {
    this.clearUser()
  },
}
</script>

<style lang="scss">
.sidebar {
    &__closedImg {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-self: center;
      width: 56px;
      height: 56px;
      margin-bottom: 15px;
      &-ico {
        widows: 100%;
        height: 100%;
        border-radius: var(--radius-50);
      }
    }
    &__closedBtn {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 48px;
      height: 48px;
      background: var(--color-orange-900);
      padding: var(--indent-14);
      border-radius: var(--indent-8);
      font-size: var(--font-14);
      line-height: var(--line-20);
      letter-spacing: 0.5px;
      font-weight: var(--font-600);
      color: var(--color-white);
      transition: background 500ms;
      margin-bottom: 15px;
      cursor: pointer;
      &:hover, &:focus {
        background: var(--color-orange-800);
    }
  }
  &__bottom {
    font-family: 'Poppins', sans-serif;
    position: relative;
  }
  &__force-id {
    display: flex;
    align-items: center;
    position: relative;
    padding: var(--indent-8);
    background: var(--color-black-750);
    color: var(--color-black-500);
    border-radius: var(--indent-8);
    transition: color 500ms;
    margin-bottom: var(--indent-8);
    &:hover {
      color: var(--color-black-100);
      cursor: pointer;
    }
  }
  &__force-id-ico {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: var(--radius-50);
    margin-right: var(--indent-8);
  }
  &__force-id-text {
    font-size: 14px;
    font-weight: 600;
  }
  &__force-id-btn-inner {
    position: absolute;
    right: 8px;
    top: 12px;
  }
  &__bottom-popup {
    min-width: 200px;
    position: absolute;
    z-index: 100;
    left: 0;
    top: -90px;
    padding: var(--indent-16);
    background: var(--color-black-800);
    border-radius: var(--indent-8);
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 30%);
    transition: all 600ms;
    @media (max-width: 991px) {
      min-width: 260px;
      right: 0;
      top: -90px;
    }
  }
  &__bottom-popupClosed {
    width: 60px;
    position: absolute;
    z-index: 100;
    left: 0;
    top: -90px;
    padding: var(--indent-16);
    background: var(--color-black-800);
    border-radius: var(--indent-8);
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 30%);
    transition: all 600ms;
  }
  &__bottom-logout {
    display: flex;
    align-items: center;
    font-size: var(--font-14);
    line-height: var(--line-20);
    font-weight: var(--font-600);
    color: var(--color-red);
    transition: color 500ms;
    cursor: pointer;
    &:hover {
      color: var(--color-orange-900);
    }
  }
  &__bottom-search {
    display: flex;
    align-items: center;
    font-size: var(--font-14);
    line-height: var(--line-20);
    margin-bottom: var(--indent-8);
    font-weight: var(--font-600);
    color: var(--color-black-500);
    transition: color 500ms;
    cursor: pointer;
    &:hover {
      color: var(--color-black-100);
    }
  }
  &__bottom--mr {
    margin-right: var(--indent-12);
  }
}
</style>
