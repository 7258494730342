<template>
    <div class="select" :class="[{ 'has-error': error }, theme]">
        <UiLabel v-if="label" :for="_uid" :label="label" :error="error" />

        <v-select
            :id="_uid"
            v-bind="$attrs"
            ref="select"
            :options="options"
            :value="value"
            :placeholder="placeholder"
            :searchable="searchable"
            @input="setSelected"
            @search="handleSearch"
            v-on="$listeners"
        >
            <template #open-indicator="{ attributes }">
                <span v-bind="attributes"> <svg-vue icon="caret" /></span>
            </template>
            <template #no-options="{}">
                <span> Не найдено</span>
            </template>
        </v-select>
    </div>
</template>

<script>
export default {
    name: "Select",
    props: {
        value: {
            type: [String, Array, Object],
            required: false,
        },
        options: {
            type: Array,
            required: true,
            default() {
                return []
            },
        },
        label: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        theme: {
            type: String,
            required: false,
            validator: (theme) => ["ghost", "clear", "polymorph", "description", "request"].includes(theme),
        },
        error: {
            type: [String, Array],
            required: false,
        },
    },
    data() {
        return {
            searchValue: "",
        }
    },
    computed: {
        placeholderText() {
            return this.placeholder || "Выберите"
        },
        getLabel() {
            return typeof this.error === "string" ? this.error : this.label
        },
    },
    methods: {
        // createOption(newOption) {
        //   if (typeof this.options[0] === 'object') {
        //     newOption = { [this.label]: newOption }
        //   }

        //   this.$emit('option:created', newOption)
        //   return newOption
        // },
        handleSearch(search, loading) {
            loading(true)

            this.searchValue = search

            loading(false)
        },

        setSelected(val) {
            this.$emit("onSelect", val)
        },
    },
}
</script>

<style lang="scss">
.select {
    .vs__dropdown-toggle {
        display: flex;
        align-items: center;
        border: 1px solid transparent;
        padding: 0;
        border-radius: 20px;
        background: var(--color-white-3);
        transition: border 0.25s ease-in-out, background 0.25s ease-in-out;
        &:hover {
            border-color: var(--color-yellow);
        }
    }

    .vs__selected-options {
        padding: 0;
        min-width: 1px;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
    }

    .vs__search,
    .vs__search:focus {
        margin: 0;
        padding: 16px 0 16px 20px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.35;
        border: 0;
        box-shadow: none;
        background: transparent;
        color: var(--color-silver);
        outline: none;
    }

    .vs__selected {
        position: relative;
        color: var(--color-yellow);
        margin: 0;
        padding: 12px 20px;
        border: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.35;
        + .vs__selected {
            padding-left: 0;
            margin-left: -6px;
            &::before {
                display: inline-block;
                content: ",";
                position: absolute;
                left: -13px;
                top: 13px;
            }
        }
        + .vs__search {
            margin-left: 0px;
            // padding-left: 0;
        }
    }

    .vs__actions {
        margin-left: auto;
        padding-top: 0;
        padding-right: 20px;
        font-size: 0;
        line-height: 1;
    }

    .vs__open-indicator {
        flex: 0 0 auto;
        fill: var(--color-silver) !important;
        font-size: 0;
        .svg-icon {
            font-size: 5px;
        }
    }

    .vs__dropdown-menu {
        position: absolute;
        top: calc(100% - 83%);
        padding: 0;
        border: 0;
        z-index: 10;
        background-color: #333333;
        border: 0px solid transparent;
        border-radius: 0 0 20px 20px;
        box-shadow: 0px 4px 15px rgba(105, 78, 75, 0.14);
    }

    .vs__dropdown-option {
        position: relative;
        padding: 14px 20px;
        color: var(--color-white);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.35;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition: background 0.1s ease-in-out, color 0.25s ease-in-out;
    }

    .vs__dropdown-option--highlight {
        background: var(--color-orange-900);
        color: var(--color-cinder);
    }

    .vs__dropdown-option--selected {
        padding-right: 16px;
        background: var(--color-orange-900);
        color: var(--color-cinder);
    }

    .vs__no-options {
        padding: 14px 20px;
        font-size: 14px;
        text-align: center;
    }

    .vs__clear {
        display: none;
        // margin-right: 12px;
        // font-size: 0;
        // .svg-icon {
        //     font-size: 10px;
        // }
        // &:hover {
        //     color: var(--color-yellow);
        // }
    }

    &.has-error {
        .vs__dropdown-toggle {
            border-color: var(--color-red) !important;
        }
    }

    .vs--open {
        .vs__open-indicator {
            transform: none;
        }
        .vs__dropdown-toggle {
            border-color: var(--color-yellow);
        }
    }

    &.clear {
        .vs__dropdown-toggle {
            border: 0;
        }
        .vs__selected,
        .vs__search {
            padding: 26px 10px;
        }
    }
}
</style>
