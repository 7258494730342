<template>
<UiModalWallet :visible="isNoticeMessage" @closeModal="closeNoticeMessage">
  <SupportNotifications />
</UiModalWallet>
</template>
<script>
import SupportNotifications from '@/components/Dashboard/SupportNotifications.vue'

export default {
  props: {
    isNoticeMessage: {
        type: Boolean,
    }
  },
  components: {
    SupportNotifications,
  },
  methods: {
    closeNoticeMessage() {
      this.$emit('closeNoticeMessage')
    }
  }
}
</script>