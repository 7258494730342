import { mapActions, mapState, mapGetters } from "vuex"
import { isEthereumWindow } from "@/helpers/crypto"
import { Log } from "@/helpers/dev"
import { LSTORAGE } from "@/config/constants"

export default {
    // mounted() {
    //     // this.attachListeners()
    // },
    beforeUnmount() {
        this.detachListeners()
    },
    computed: {
        ...mapState("crypto", ["provider"]),
        ...mapGetters("crypto", ["isConnected"]),
    },
    methods: {
        async attachListeners() {
            const [windowError, ethWindowName] = await isEthereumWindow()
            if (windowError) return

            this.provider.on("accountsChanged", this.handleAccountChange)
            this.provider.on("chainChanged", this.handleChainChange)
            // this.provider.on("message", this.handleEthMessage)
        },
        async handleAccountChange(accounts) {
            const newAccount = accounts[0]
            Log(`switched to newAccount ${newAccount}`)

            // await this.initApp({ type: "switch" })
            localStorage.removeItem(LSTORAGE.connected)
            localStorage.removeItem(LSTORAGE.wallet)
            localStorage.removeItem(LSTORAGE.walletconnect)
            localStorage.removeItem(LSTORAGE.token)
            // must be using exact name in component
            // this.onAccountSwitch && this.onAccountSwitch()

            window.location.reload()
        },
        handleChainChange(chainId) {
            window.location.reload()
        },
        handleEthMessage(message) {
            Log("eth message", message)
        },
        async detachListeners() {
            const [windowError, ethWindowName] = await isEthereumWindow()
            if (windowError) return

            this.provider.removeListener("accountsChanged", this.handleAccountChange)
            this.provider.removeListener("chainChanged", this.handleChainChange)
            // this.provider.removeListener("message", this.handleEthMessage)
        },
        ...mapActions("crypto", ["initApp"]),
    },
    watch: {
        isConnected(newVal, oldVal) {
            if (newVal === true && !oldVal) {
                this.attachListeners()
            } else if (newVal === false) {
                this.detachListeners()
            }
        },
    },
}
