export default {
    state: {
        connectionModal: {
            visible: false,
            type: "connect",
        },
        searchModal: {
            visible: false,
            type: "modal",
        },
        activationModal: {
            visible: false,
            type: "",
            level: 0,
        },
        cancelModal: {
            visible: false,
        },
        cancelSuccessModal: {
            visible: false,
        },
        termsModal: {
            visible: false,
        },
        isSideBarClose: false
    },
    getters: {
        connectionModal(state) {
            return state.connectionModal
        },
        connectionModalVisible(state) {
            return state.connectionModal.visible
        },

        searchModal(state) {
            return state.searchModal
        },

        activationModal(state) {
            return state.activationModal
        },
        activationModalVisible(state) {
            return state.activationModal.visible
        },

        cancelModal(state) {
            return state.cancelModal
        },
        cancelSuccessModal(state) {
            return state.cancelSuccessModal
        },
        termsModal(state) {
            return state.termsModal
        },
        isSideBarClose(state) {
            return state.isSideBarClose
        }
    },

    mutations: {
        setIsSideBarClose(state, bool) {
            state.isSideBarClose = bool
        },
        setConnectionModal(state, payload) {
            state.connectionModal = {
                ...state.connectionModal,
                ...payload,
            }
        },
        setActivationModal(state, payload) {
            state.activationModal = {
                ...state.activationModal,
                ...payload,
            }
        },
        setSearchModal(state, payload) {
            state.searchModal = {
                ...state.activationModal,
                ...payload,
            }
        },
        setCancelModal(state, payload) {
            state.cancelModal = {
                ...state.cancelModal,
                ...payload,
            }
        },
        setCancelSuccessModal(state, payload) {
            state.cancelSuccessModal = {
                ...state.cancelSuccessModal,
                ...payload,
            }
        },
        setTermsModal(state, payload) {
            state.termsModal = {
                ...state.termsModal,
                ...payload,
            }
        },
    },
    actions: {},

    namespaced: true,
}
