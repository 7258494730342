<template>
  <span v-html="icon" @click="$emit('event')" :class="classes"></span>
</template>

<script>
import icons from '../UI/icons'

export default {
  props: {
    name: String,
    verAlign: Boolean,
  },

  emits: ['event'],

  data() {
    return {
      icon: icons[this.name],
    }
  },

  computed: {
    classes() {
      const classes = ['ico']

      if (this.verAlign) {
        classes.push('ico--align')
      }
      return classes
    },
    
  },
}
</script>

<style lang="scss">
.ico {
  transition: opacity .4s;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
  &--align {
    display: flex;
    align-items: center;
  }
}
</style>
