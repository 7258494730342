<template>
    <div class="sidebar" :class="[isDesctopSideBar && 'hideDesctopMenu', mini && 'hide']">
        <div class="sidebar-inner">
            <div class="sidebar__body">
                <div class="sidebar-inner__top" :class="[isDesctopSideBar && 'isHideIcon']">
                    <router-link to="/" class="sidebar__logo logo d-flex align-center">
                        <BaseIcon name="mainLogo" verAlign/>
                    </router-link>
                    
                    <div class="center">
                        <BaseIcon name="burgerMenu" class="sidebar-burger" @event="openMenu" v-show="isActiveWallet"/>
                    </div>
                    <BaseIcon 
                        name="logout" 
                        class="open-close__btn"
                        :class="isDesctopSideBar && 'closeSideBar__btn'"
                        @event="isDesctopMenu" 
                        v-if="isActiveWallet"
                    />

                </div>
                <!-- <div class="sidebar-languages">
                    <Language class="languages--light" :selected="selected" @select="optionSelect" :options="options" />
                </div> -->
                <Menu 
                    :func="openMenu"
                    :isClosed="isDesctopSideBar"
                />
            </div>
            <div class="sidebar-inner__buttons">
                <SidebarBottom 
                    @logout="exit" 
                    @isSearch="openSearch"
                    :isClosed="isDesctopSideBar"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex"
import Menu from "./Menu.vue"
// import Language from "../UI/Language.vue"
import BaseIcon from '@/components/UI/BaseIcon.vue'
import SidebarBottom from '../Dashboard/SidebarBottom.vue'
export default {
    data() {
        return {
            mini: false,
            isDesctopSideBar: false,
            windowWidth: window.innerWidth,
            options: [
                { name: "ru", value: 1 },
                { name: "en", value: 2 },
                { name: "cn", value: 3 }
            ],
            selected: "ru",
            intervalCount: 0,
            timer: null
        }
    },
    async mounted() {
        // this.timer = setInterval(() => {
        //     this.fetchData()
        //     this.intervalCount = 1
        // }, 6000)
        // if (this.isConnected === true) {
        //     await this.fetchData()
        // }
        window.addEventListener('click', (event) => {
            if (!this.$el.contains(event.target)) {
                this.mini = false
                this.isDesctopSideBar = false
                this.setIsSideBarClose(false)
            }
        })
    },
    watch: {
        intervalCount(oldval,newval) {
            clearInterval(this.timer)
        }
    },
    components: {
        Menu,
        BaseIcon,
        SidebarBottom,
        // Language,
    },
    computed: {
        ...mapGetters("crypto", ["isActiveWallet", "getToken"]),
        ...mapState('user', ['currentUser']),
        ...mapGetters('crypto', ['isConnected']),
    },
    methods: {
        ...mapActions("crypto", ["logOut"]),
        ...mapMutations("user", ["clearUser"]),
        ...mapMutations("ui", ["setSearchModal", "setIsSideBarClose"]),
        ...mapActions("ticket", ["getUnreadedMessages"]),
        
        async fetchData() {
            await this.getUnreadedMessages(this.currentUser.name)
            setInterval(() => {
                this.getUnreadedMessages(this.currentUser.name)
            }, 120000)
        },
        optionSelect(option) {
            this.selected = option.name
        },
        // addClass() {
            //     this.mini = !this.mini

            //     document
            //         .querySelectorAll(".nav-list__link")
            //         .forEach((link) => link.classList.toggle("nav-list__link--hide"))
            // },
            // addClassOnResize() {
                //     this.mini = true
                // },
                
                exit() {
                    this.logOut()
                    this.clearUser()
                },
                isDesctopMenu() {
                    this.isDesctopSideBar = !this.isDesctopSideBar
                    this.setIsSideBarClose(true)
                    // const sidebar = document.getElementsByClassName('.sidebar')
                    // document.querySelectorAll(".nav-list__link").forEach((link) => link.classList.toggle("nav-list__link--hide"))
                    // this.isDesctopSideBar ? sidebar.classList.remove("hideDesctopMenu") : sidebar.classList.add("hideDesctopMenu")
                },
                
                openMenu() {
                    const body = document.body

            this.mini = !this.mini
            body.classList.contains("hidden") ? body.classList.remove("hidden") : body.classList.add("hidden")
        },

        openSearch() {
            this.setSearchModal({ visible: true, type: "modal" })
        },

    },
}
</script>

<style lang="scss">
.isHideIcon {
    margin-left: 7px;
}
.open-close__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: var(--indent-32);
    height: var(--indent-32);
    background: #292929;
    border-radius: var(--indent-8);
    margin-left: var(--indent-32);
    cursor: pointer;
         @media(max-width: 991px) {
            display: none;
         }
}

.sidebar-burger {
    width: 34px;
    height: 34px;
    color: var(--color-white-50);
    stroke: var(--color-white-50);
}
.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.hideDesctopMenu {
    width: 80px !important;
    transition: 0.3s all ease-in-out;
}
.closeSideBar__btn {
    display: none;
}
</style>