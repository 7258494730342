<template>
    <UiModalWallet :visible="isModal" @closeModal="closeWallet">
        <div v-if="getAccount" class="wallet" data-parent="full-backdrop" data-toggle="dropdown" @click.stop>
            <div class="dropdown">
                <div class="wallet__wrapper wallet__balance">
                    <div class="title">
                        <a href="https://ib.meta-force.space/" target="_blank" class="title__link">
                            <div class="title__link-text">{{ $t('dashboardInfo.modalWallet.innerBalance') }}</div>
                            <BaseIcon name="logoutBlack" verAlign/>
                        </a>
                        <button v-if="isMobile" class="title--close btn-reset" type="button" @click.stop="closeWallet">
                            <svg-vue icon="close"/>
                        </button>
                    </div>
                    <div class="wallet__list">
                        <!-- <div class="wallet__list-item active">
                            <div class="img">
                                <BaseIcon :name="dai.iconName" verAlign />
                            </div>
                            <div class="name">
                                {{ dai.label }}
                            </div>
                            <div class="sum">
                                {{ dai.amount }}
                            </div>
                            <div class="buttons">
                                <div class="button">
                                    <BaseIcon name="arrowUp" verAlign />
                                    <span>Replenish</span>
                                </div>
                                <div class="button">
                                    <BaseIcon name="arrowUp" verAlign class="arrowBtn" />
                                    <span>Send to wallet</span>
                                </div>
                            </div>
                        </div> -->
                        <div class="wallet__list-item">
                            <div class="img">
                                <BaseIcon :name="forceCoinInnerBalance.iconName" verAlign/>
                            </div>
                            <div class="name">
                                {{ forceCoinInnerBalance.label }}
                            </div>
                            <div class="sum">
                                {{ forceCoinInnerBalance.amount }}
                            </div>
                        </div>
                        <div class="wallet__list-item">
                            <div class="imgEnergy">
                                E
                            </div>
                            <div class="name">
                                {{ energyCoinInnerBalance.label }}
                            </div>
                            <div class="sum">
                                {{ energyCoinInnerBalance.amount }}
                            </div>
                        </div>
                        <div class="wallet__list-item">
                            <div class="img">
                                <BaseIcon :name="dai.iconName" verAlign/>
                            </div>
                            <div class="name">
                                {{ daiInnerBalance.label }}
                            </div>
                            <div class="sum">
                                <!-- {{ dai.amount }} -->
                                {{ daiInnerBalance.amount }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wallet__wrapper wallet__main">
                    <div class="titleWallet">
                        <div class="titleWallet__wrap">
                            <span>
                                {{ $t('register.wallet') }}:
                            </span>
                            <p v-if="guestWalletShort">
                                {{ guestWalletShort }}
                                <BaseIcon
                                        class="copy"
                                        name="copy"
                                        @event="handleClipboardCopy"
                                />
                            </p>
                            <p v-else>
                                {{ clientWalletShort }}
                                <BaseIcon
                                        class="copy"
                                        name="copy"
                                        @event="handleClipboardCopy"
                                />
                            </p>
                            <span v-if="metaPaymentReservedAddress">{{  $t('dashboardInfo.modalWallet.extraWallet') }}</span>
                            <p v-if="extraWalletShort">
                                {{ extraWalletShort }}
                                <BaseIcon
                                    class="copy"
                                    name="copy"
                                    @event="handleExtraClipBoard"
                                />
                            </p>
                            <label class="wallet__label" v-if="isDirectPaymentStatusLoaded && !extraWalletShort">
                                <span class="wallet__custom-checkbox" @click.prevent.stop="changeDirectPaymentStatus">
                                    <BaseIcon name="checkboxActive" verAlign v-show="isRewardsChecked" />
                                </span>
                                <input class="wallet__checkbox" type="checkbox" :checked="directPaymentStatus" />
                                Receive rewards to the wallet
                            </label>
                        </div>

                        <button class="button button-sm grey"
                                        @click.stop="exit">{{ $t('dashboardInfo.modalWallet.disconnect') }}</button>
                    </div>
                    <div class="wallet__list">
                        <div class="wallet__list-item">
                            <div class="img">
                                <BaseIcon :name="matic.iconName" verAlign/>
                            </div>
                            <div class="name">
                                {{ matic.label }}
                            </div>
                            <div class="sum">
                                {{ matic.amount }}
                            </div>
                        </div>
                        <div class="wallet__list-item">
                            <div class="img">
                                <BaseIcon :name="dai.iconName" verAlign/>
                            </div>
                            <div class="name">
                                {{ dai.label }}
                            </div>
                            <div class="sum">
                                {{ dai.amount }}
                            </div>
                        </div>
                        <div v-for="(item, idx) in statTokensFiltered" :key="idx" class="wallet__list-item">
                            <div class="img">
                                <BaseIcon :name="item.iconName"/>
                            </div>
                            <div class="name">
                                {{ item.label }}
                            </div>
                            <div class="sum">
                                {{ item.amount }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </UiModalWallet>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import BaseIcon from '@/components/UI/BaseIcon.vue'
import {sliceString} from '@/helpers/sliceString'
import {copyToClipboard} from '@/helpers/clipboard'
import pageReadyByConnect from "@/mixin/pageReadyByConnect";
import {SentryLog} from "@/helpers/dev";


export default {
    props: {
        isModal: {
            type: Boolean,
        }
    },
    components: {
        BaseIcon,
    },
    mixins: [pageReadyByConnect],
    data() {
        return {
            account: null,
            metaPaymentReservedAddress: null,
            forceCoinInnerBalance: {
                iconName: 'cryptoForceCoin',
                label: 'Forcecoin',
                amount: '-',
            },
            energyCoinInnerBalance: {
                iconName: 'cryptoEnergyCoin',
                label: 'Energy',
                amount: 0,
            },
            daiInnerBalance: {
                iconName: 'cryptoEnergyCoin',
                label: 'DAI',
                amount: 0,
            },
            isDirectPaymentStatusLoaded: false,
            directPaymentStatus: false,
            shortStrMobile: false,
            isMobile: false,
            isRewardsChecked: false
        }
    },
    created() {
        if (window.innerWidth < 400) {
            this.shortStrMobile = true
        }
        if (window.innerWidth < 769) {
            this.isMobile = true
        }
    },
    async mounted() {
        await this.actualAccount();
        if (this.isConnected) {
            await this.pageReady()
        }
    },
    computed: {
        ...mapGetters('crypto', ['getAccount', 'getBalance']),

        clientWalletShort() {
            if (!this.getAccount) return
            if (this.shortStrMobile) {
                return sliceString(this.getAccount)
            }
            return sliceString(this.getAccount, 12)
        },
        guestWalletShort() {
            if (!this.account) return
            if (this.shortStrMobile) {
                return sliceString(this.account)
            }
            return sliceString(this.account, 12)
        },
        extraWalletShort() {
            if (!this.metaPaymentReservedAddress) return
            if (this.shortStrMobile) {
                return sliceString(this.metaPaymentReservedAddress)
            }
            return sliceString(this.metaPaymentReservedAddress, 12)
        },

        statTokens() {
            const coinValues = Object.values(this.getBalance).map((el) => +el)
            const coinNames = ['Matic', 'SFC old', 'SFC new', 'SFC2', 'DAI']
            const res = []

            coinValues.forEach((el, idx) => {
                res.push({
                    iconName: `crypto${coinNames[idx].replace(/[' ']/gi, '')}`,
                    label: coinNames[idx],
                    amount: el,
                })
            })

            return res
        },
        matic() {
            return this.statTokens.find((el) => el.iconName.includes('cryptoMatic'))
        },
        dai() {
            return this.statTokens.find((el) => el.iconName.includes('cryptoDAI'))
        },
        statTokensFiltered() {
            return this.statTokens.filter(
                (el) =>
                    !el.iconName.toLowerCase().includes('matic') &&
                    !el.iconName.toLowerCase().includes('dai') &&
                    !el.iconName.toLowerCase().includes('force coin') &&
                    el.amount > 0
            )
        },
    },
    methods: {
        ...mapActions("user", ["guessUser"]),
        ...mapActions("crypto", ["logOut", "getMetaPaymentBalancesFormatted", "getMetaPaymentReservedAddress",
            "getMetaPaymentDirectPaymentStatus", "setMetaPaymentDirectPaymentStatus"]),
        ...mapMutations("user", ["clearUser"]),

        async pageReady() {
            try {
                const balances = await this.getMetaPaymentBalancesFormatted(this.getAccount);
                this.daiInnerBalance.amount = balances[0];
                this.forceCoinInnerBalance.amount = balances[1];
                this.energyCoinInnerBalance.amount = balances[2];
            } catch (e) {
                console.log('getMetaPaymentBalancesFormatted error:', e);
            }

            try {
                this.metaPaymentReservedAddress = await this.getMetaPaymentReservedAddress(this.getAccount);
            } catch (e) {
                console.log('getMetaPaymentReservedAddress error:', e);
            }
            await this.loadDirectPaymentStatus()
        },

        exit() {
            this.logOut()
            this.clearUser()
            this.$emit("closeWallet")
        },
        closeWallet() {
            this.$emit("closeWallet")
        },
        swap() {
            this.$emit('swap')
        },
        handleExtraClipBoard() {
            if(this.metaPaymentReservedAddress) {
                copyToClipboard(this.metaPaymentReservedAddress).then(() => {
                    this.$toast.success(`${this.metaPaymentReservedAddress} ${this.$t('account.copied')}`)
                })
                .catch(() => {
                    this.$toast.error(`${this.$t('account.copyError')}`)
                })
            }
        },

        handleClipboardCopy() {
            if (!this.getAccount) return
            // navigator.clipboard.writeText(text)
            if (this.account) {
                copyToClipboard(this.getAccount).then(() => {
                    this.$toast.success(`${this.account} ${this.$t('account.copied')}`)
                })
                    .catch(() => {
                        this.$toast.error(`${this.$t('account.copyError')}`)
                    })
            } else {
                copyToClipboard(this.getAccount).then(() => {
                    this.$toast.success(`${this.getAccount} ${this.$t('account.copied')}`)
                })
                    .catch(() => {
                        this.$toast.error(`${this.$t('account.copyError')}`)
                    })
            }
        },

        async actualAccount() {
            let targetAccount
            const accountQuery = this.$route.query.account
            if (accountQuery) {
                targetAccount = accountQuery
            } else {
                targetAccount = this.getAccount
            }

            if (!targetAccount) return
            try {
                const data = await this.guessUser(targetAccount)
                this.account = data.account
            } catch (e) {
                this.$swal(this.$t("errors.userNotFound"))
            }
        },
        async loadDirectPaymentStatus() {
            try {
                this.directPaymentStatus = await this.getMetaPaymentDirectPaymentStatus(this.getAccount);
                this.isDirectPaymentStatusLoaded = true;
            } catch (e) {
                console.log('getMetaPaymentDirectPaymentStatus error:', e);
            }
        },
        async changeDirectPaymentStatus() {
            this.isRewardsChecked = true
            try {
                this.$store.commit("loader/showProcessingModal", {
                    isShown: true,
                    text: `${this.$t("matrix.activationModal.blockchainPendingFirstLine")} <br />
                                ${this.$t("matrix.activationModal.blockchainPendingSecondLine")}`
                })

                await this.setMetaPaymentDirectPaymentStatus({
                    status: !this.directPaymentStatus,
                    onBlockchainPending: () => {
                        this.$store.commit("loader/showProcessingModal", {
                            isShown: true,
                            text: `${this.$t("matrix.activationModal.blockchainPendingFirstLine")} <br />
                                ${this.$t("matrix.activationModal.blockchainPendingSecondLine")}`
                        })
                    }
                });

                this.directPaymentStatus = !this.directPaymentStatus;
                // await this.loadDirectPaymentStatus();
            } catch (e) {
                SentryLog(e, "connect")
                this.$swal(e.message)
                this.setConnecting(null)
            } finally {
                this.$store.commit("loader/showProcessingModal", {
                    isShown: false
                })
                this.isRewardsChecked =  this.directPaymentStatus
            }
        }
    }

}
</script>
