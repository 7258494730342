import { $api } from "@/plugins/Axios"
import endpoint from "@/config/api-endpoint"
import { mapApiError, mapData } from "./helpers"

export const getTopicsService = async (request) => {
    try {
        const { data } = await $api.get(endpoint.ticket.topics, { params: { ...request } })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

// topic_id : integer|required or custom_topic : string|required
// 	incident_date : string
// 	message: string|required
// 	email: string|required
// 	files: array|images
export const createTicketService = async (request) => {
    try {
        const formData = new FormData()
        Object.keys(request).forEach((key) => {
            if (key === "files") {
                for (let i = 0; i < request[key].length; i++) {
                    formData.append(`${key}[${i}]`, request[key][i])
                }
            } else {
                formData.append(key, request[key])
            }
        })

        const { data } = await $api.post(endpoint.ticket.create, formData, {
            headers: {
                Accept: "application/json",
            },
        })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const getTicketListService = async (request) => {
    try {
        const { data } = await $api.get(endpoint.ticket.list, { params: { ...request } })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const getFaqService = async (request) => {
    try {
        const { data } = await $api.get(endpoint.ticket.faq, { params: { ...request } })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const getTicketService = async (request) => {
    try {
        const { data } = await $api.get(endpoint.ticket.getById(request.id), { params: { ...request } })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const getTicketMessagesService = async (request) => {
    try {
        const { data } = await $api.get(endpoint.ticket.getMessages(request.id), { params: { ...request } })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

// message: string|required
// files: array|images
export const createMessageService = async (request) => {
    try {
        const formData = new FormData()
        if (request.files) {
            for (let i = 0; i < request.files.length; i++) {
                formData.append(`files[${i}]`, request.files[i])
            }
        }
        if (request.message) {
            formData.append("message", request.message)
        }

        const { data } = await $api.post(endpoint.ticket.createMessage(request.id), formData)

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const closeTicketService = async (request) => {
    try {
        const { data } = await $api.put(endpoint.ticket.close(request.id))

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}


export const getTicketMessagesCount = async (userId) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const { data } = await $api.get(endpoint.ticket.state(userId))
    return data
  } catch (error) {
    throw error
  }
}

export const switchTicketMessagesIsreaded = async (payload) => {
    // eslint-disable-next-line no-useless-catch
    try {
        const { data } = await $api.put(endpoint.ticket.makeRead, payload)
        return data
      } catch (error) {
        throw error
      }
}