import {
    getUsersService,
    getUserByIdService,
    getUserByFieldService,
    isValidAccountService,
    registerAccountService,
    isLevelActiveService,
    getParentsCountService,
    setLevelService,
    getTransactionsService,
    sendTransactionService
} from "@/api/user"
import { programArray, getClassicTypeByLevel, getClassicPriceByLevel } from "@/helpers/matrix"
import { isForceId, isHashAddress } from "@/helpers/user"

export default {
    state: {
        currentUser: null,
        classicMatrix: [],
        boostMatrix: []
    },
    getters: {
        getLastActive(state) {
            if (state.classicMatrix && state.classicMatrix.length > 0) {
                const lastActive = state.classicMatrix.findLast((x) => x.active)
                return lastActive || null
            }
            return null
        },
        previousBuyLevel(state, getters) {
            // find previous active program, to indicate autoupgrade buttons
            if (getters.getLastActive === null) return 0

            return getters.getLastActive.lvl
        },
        currentBuyLevel(state, getters) {
            // find last active program, add +1 to indicate next possible buy
            if (getters.getLastActive === null) return 0

            return getters.getLastActive.lvl + 1
        },
        totalActiveLevels(state) {
            let returnable = 0
            if (state.classicMatrix && state.classicMatrix.length) {
                return state.classicMatrix.reduce((acc, x) => (x.active ? acc + 1 : acc), 0)
            }

            return returnable
        },

        gappedLevel(state) {
            if (state.classicMatrix && state.classicMatrix.length > 0) {
                const gapLevel = state.classicMatrix.find((x) => x.active === "gap")
                return gapLevel ? gapLevel.lvl : null
            }

            return null
        },
    },
    mutations: {
        setClassicMatrix(state, obj) {
            const classic = programArray(obj)

            let cards = classic.map((x, i) => ({
                lvl: i, // fixed
                type: getClassicTypeByLevel(i), // fixed
                cost: getClassicPriceByLevel(i), // fixed
                partners: x.partners, //backend
                slots: x.slot, //backend
                active: x.setLevelactive // contract
            }))

            state.classicMatrix = cards
        },
        setBoostMatrix(state) {
            const classic = [0, 1, 2, 3]

            let cards = classic.map((x, i) => ({
                lvl: i, // fixed
                type: getClassicTypeByLevel(i),
                cost: getClassicPriceByLevel(i),
                partners: 0,
                slots: 0,
                active: false
            }))

            state.boostMatrix = cards
        },
        updateClassicMatrixLevels(state, arr) {
            if (arr && arr.length) {
                state.classicMatrix = arr.map((x) => {
                    return {
                        ...state.classicMatrix[x.lvl],
                        ...x
                    }
                })
            }
        },
        setClassMatrixLevel(state, { lvl, active }) {
            let matrix = state.classicMatrix
            matrix[lvl] = {
                ...matrix[lvl],
                ...{
                    lvl,
                    active
                }
            }

            state.classicMatrix = matrix.map((x) => ({ ...x }))
        },
        saveUserToStore(state, res) {
            state.currentUser = res
        },
        clearUser(state) {
            state.currentUser = null
            localStorage.removeItem('currentUserLocal')
        }
    },
    actions: {
        async guessUser({ dispatch }, input) {
            let userResponse = null
            if (isHashAddress(input)) {
                const { users } = await dispatch("getUserByField", { account: input })
                userResponse = users
            } else if (isForceId(input)) {
                const { users } = await dispatch("getUserByField", { name: input })
                userResponse = users
            } else if (Number(input)) {
                userResponse = await dispatch("getUser", { id: input })
            }

            return userResponse
        },
        async getUsers({ commit, dispatch }, request) {
            const [err, result] = await getUsersService()

            if (err) throw err
            return result
        },

        async getUser({ commit, dispatch }, request) {
            const [err, result] = await getUserByIdService(request)

            if (err) throw err

            return result
        },

        async isValidAccount({ commit, dispatch }, request) {
            return await isValidAccountService(request)
        },

        async getUserByField({ commit, dispatch }, request) {
            const [err, result] = await getUserByFieldService(request)

            if (err) throw err

            return result
        },

        async registerAccount({ commit, dispatch }, request) {
            const [err, result] = await registerAccountService(request)

            if (err) throw err

            return result
        },
        async isActiveLevel({ commit, dispatch }, request) {
            const [err, result] = await isLevelActiveService(request)

            if (err) throw err

            return result
        },
        async getParentsCount({ commit, dispatch }, request) {
            const [err, result] = await getParentsCountService(request)

            if (err) throw err

            return result
        },
        async setLevel({ commit, dispatch }, request) {
            const [err, result] = await setLevelService(request)

            if (err) throw err

            return result
        },
        async getTransactions({ commit }, request) {
            const [err, result] = await getTransactionsService(request)

            if (err) throw err

            return result
        },
        async sendTransaction({ commit, dispatch }, request) {
            const [err, result] = await sendTransactionService(request)

            if (err) throw err

            return result
        },
        async saveUser({ state, commit }, obj) {
            let userLocal = localStorage.getItem('currentUserLocal')
            if (!Boolean(userLocal) || userLocal === 'undefined') {
                commit('saveUserToStore', obj)
                localStorage.setItem('currentUserLocal', JSON.stringify(state.currentUser))
            } else {
                commit('saveUserToStore', JSON.parse(userLocal))
            }
        }
    },
    namespaced: true
}
