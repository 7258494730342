<template>
    <div class="modal" v-if="connectionModalVisible">
        <button @click="closeModal" class="modal-close btn-reset">
            <svg-vue icon="close" />
        </button>
        <div class="modal__container">
            <div class="modal-inner">
                <h2 class="modal__title section-title">{{ title }}</h2>
                <ul class="modal-list list-reset">
                    <li
                        class="modal-list__item modal-item"
                        :class="[!metaMaskAvailable && 'disabled']"
                        @click="handleWalletSelect('metamask')"
                    >
                        <div class="modal-item__logo">
                            <svg-vue icon="wallet_metamask" />
                        </div>
                        <div class="modal-item__info">
                            <h3 class="modal-item__title">MetaMask</h3>
                            <p class="modal-item__subtitle">
                                {{ this.$t("connectModal.desktop") }} /
                                {{ this.$t("connectModal.dapp") }}
                            </p>
                        </div>
                    </li>
                    <li
                        class="modal-list__item modal-item"
                        :class="[!trustAvailable && 'disabled']"
                        @click="handleWalletSelect('trust')"
                    >
                        <div class="modal-item__logo">
                            <svg-vue icon="wallet_trustwallet" />
                        </div>
                        <div class="modal-item__info">
                            <h3 class="modal-item__title">Trust Wallet</h3>
                            <p class="modal-item__subtitle">
                                {{ `${this.$t("connectModal.dapp")}` }}
                            </p>
                        </div>
                    </li>
                    <li class="modal-list__item modal-item" @click="handleWalletSelect('walletconnect')">
                        <div class="modal-item__logo">
                            <svg-vue icon="wallet_walletconnect" />
                        </div>
                        <div class="modal-item__info">
                            <h3 class="modal-item__title">WalletConnect</h3>
                            <p class="modal-item__subtitle">
                                {{ `${this.$t("connectModal.anyApp")}` }}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
import { nullEmptyHash } from "@/helpers/matrix"
import { SentryLog } from "@/helpers/dev"

export default {
    data() {
        return {}
    },
    computed: {
        metaMaskAvailable() {
            const { ethereum } = window
            try {
                return ethereum && ethereum.isMetaMask
            } catch (e) {
                return false
            }
        },
        trustAvailable() {
            const { ethereum } = window
            try {
                return ethereum && ethereum.isTrust
            } catch (e) {
                return false
            }
        },
        isSelectWallet() {
            return this.connectionModal.type === "select"
        },
        title() {
            return this.isSelectWallet ? this.$t("selectWallet") : this.$t("connectWallet")
        },
        ...mapGetters("ui", ["connectionModal", "connectionModalVisible"]),
        ...mapGetters("crypto", ["isConnecting", "getParent"])
    },
    methods: {
        async handleWalletSelect(name) {
            if (this.isSelectWallet) {
                const params = this.connectionModal.params || {}
                this.setConnectionWallet({ name, ...params })
                this.closeModal()
                return
            }

            await this.initApp({ name, onConnect: this.closeModal() })
                .then(async () => {
                  if (this.isConnecting === false) {
                    if (nullEmptyHash(this.getParent) === null) {
                      this.$swal({
                        title: this.$t("connectModal.swal.title"),
                        showCancelButton: true,
                        confirmButtonColor: "#38c948",
                        cancelButtonColor: "#828282",
                        confirmButtonText: this.$t("connectModal.swal.confirmButtonText"),
                        cancelButtonText: this.$t("connectModal.swal.cancelButtonText")
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          await this.$router.push({name: "signup"})
                        } else {
                          await this.logOut()
                        }
                      })
                    } else {
                      try {
                        this.$store.commit("loader/showProcessingModal", {
                          isShown: true,
                          text: `${this.$t("matrix.activationModal.blockchainPendingFirstLine")} <br />
                                ${this.$t("matrix.activationModal.blockchainPendingSecondLine")}`
                        })

                        await this.checkMetaCoreRegistration({
                          onBlockchainPending: () => {
                            this.$store.commit("loader/showProcessingModal", {
                              isShown: true,
                              text: `${this.$t("matrix.activationModal.blockchainPendingFirstLine")} <br />
                                ${this.$t("matrix.activationModal.blockchainPendingSecondLine")}`
                            })
                          }
                        });

                        location.href = "/home"
                      } catch (e) {
                        SentryLog(e, "connect")
                        this.$swal(e.message)
                        this.setConnecting(null)
                      } finally {
                        this.$store.commit("loader/showProcessingModal", {
                          isShown: false
                        })
                      }
                      // this.$router.push({ name: "dashboard" })
                    }
                  }
                })
                .catch((err) => {
                    SentryLog(err, "connect")
                    this.$swal(err.message)
                    this.setConnecting(null)
                })
        },

        closeModal: function() {
            this.setConnectionModal({ visible: false })
        },
        ...mapMutations("ui", ["setConnectionModal"]),
        ...mapActions("crypto", ["initApp", "init", "logOut", "checkMetaCoreRegistration"]),
        ...mapMutations("crypto", ["setConnecting", "setConnectionWallet"])
    }
}
</script>

<style lang="scss">
.modal {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #23222c;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    &-close {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 24px;
        right: 24px;
        width: 40px;
        height: 40px;
        background-color: var(--color-cinder);
        border-radius: 100%;
    }

    &-inner {
        max-width: 540px;
        width: 100%;
        padding: 40px;
        background-color: var(--color-cinder);
        border-radius: 10px;
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    }

    &-list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    &-item {
        display: flex;
        align-items: center;

        cursor: pointer;
        padding: 20px;
        border-radius: 10px;
        transition: background-color 0.25s ease-in-out, transform 0.25s ease-in-out;
        background-color: var(--color-charade);

        &:hover {
            transform: scale(1.05);
            background-color: var(--color-yellow);
            .modal-item__title,
            .modal-item__subtitle {
                color: var(--color-charade);
            }
        }
        &.disabled {
            filter: grayscale(1);
            pointer-events: none;
        }
        &__title,
        &__subtitle {
            transition: color 0.25s ease-in-out;
        }
        &__logo {
            margin-right: 20px;
            width: 30px;
            height: 30px;
        }

        &__subtitle {
            font-size: 16px;
            color: var(--color-silver);
            transition: color 0.25s ease-in-out;
        }
    }
}
</style>
