<template>
    <div class="activation-modal" :class="[activationModal.visible && 'show']" @click="closeModal">
        <div class="activation-modal__inner" @click.stop>
            <button class="activation-modal__close btn-reset" type="button" @click="closeModal">
                <svg-vue icon="close" />
            </button>
            <svg-vue class="activation-modal__icon" icon="activation-icon" />
            <h2 class="activation-modal__title">
                {{ $t("matrix.activationModal.activationOfLevel") }} {{ activationModal.level + 1 }} ({{
                    activationModal.type
                }})
            </h2>
            <p class="activation-modal__buy">{{ $t("matrix.activationModal.youAreBuying") }}:</p>
            <span class="activation-modal__buy-lvl">
                lvl {{ activationModal.level + 1 }} — {{ activationModal.cost }} DAI
            </span>
            <div class="activation-modal__divider"></div>
            <div
                class="activation-modal__status"
                :class="[process[0] === false && 'reject', process[0] === true && 'resolve']"
            >
                <p class="activation-modal__status-text">
                    {{ $t("matrix.activationModal.connectionCheck") }} (
                    <span>
                        {{ $t("matrix.activationModal.chain") }}
                    </span>
                    )
                </p>
                <svg-vue class="activation-modal__status-resolve" icon="svg-resolve" />
                <svg-vue class="activation-modal__status-reject" icon="svg-reject" />
            </div>
            <div
                class="activation-modal__status"
                :class="[process[1] === false && 'reject', process[1] === true && 'resolve']"
            >
                <p class="activation-modal__status-text">
                    {{ $t("matrix.activationModal.balanceCheck") }} (
                    <span>{{ activationModal.cost }} DAI</span>
                    )
                </p>
                <svg-vue class="activation-modal__status-resolve" icon="svg-resolve" />
                <svg-vue class="activation-modal__status-reject" icon="svg-reject" />
            </div>
            <div
                class="activation-modal__status"
                :class="[process[2] === false && 'reject', process[2] === true && 'resolve']"
            >
                <p class="activation-modal__status-text">
                    {{ $t("matrix.activationModal.balanceCheck") }} (
                    <span>{{ estimatedGas }} MATIC</span>
                    )
                </p>
                <svg-vue class="activation-modal__status-resolve" icon="svg-resolve" />
                <svg-vue class="activation-modal__status-reject" icon="svg-reject" />
            </div>

            <div class="activation-modal__confirm">
                <UiCheckbox :value="agreeTerms" :error="agreeError" @onChange="(v) => (agreeTerms = v)">
                    <span v-html="$t('matrix.activationModal.agree')"></span>
                </UiCheckbox>
            </div>

            <button class="activation-modal__pay btn-reset glob-btn" type="button" @click.stop.prevent="handleBuyLevel">
                <UiLoader v-if="loading" :loading="true" theme="btn" color="#000" />
                <span v-else>{{ $t("matrix.activationModal.proceed") }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"

export default {
    data() {
        return {
            loading: false,
            process: [null, null, null],
            estimatedGas: "0",
            agreeTerms: false,
            agreeError: false
        }
    },
    computed: {
        ...mapGetters("ui", ["activationModal", "activationModalVisible"]),
        ...mapGetters("crypto", [
          "getAccount",
        ]),
        ...mapState("crypto", ["balance"])
    },
    methods: {
        async handleBuyLevel() {
            if (this.loading) return

            this.agreeError = false

            if (!this.agreeTerms) {
                this.agreeError = true
                return
            }

            this.process = [null, null, null]
            this.loading = true
            this.estimatedGas = "0"

            const updateProgress = (i, v) => {
                this.process = [...this.process.map((x, idx) => (idx === i ? v : x))]
            }

            // const [conErr, account] = await this.checkConnect()
            // if (conErr) {
            //     updateProgress(0, false)
            //     this.loading = false
            //     return
            // }
            const account = this.getAccount
            updateProgress(0, true)

            const [balErr] = await this.getBalances(account)
            const { busd } = this.balance
            const enoughBusd = busd >= this.activationModal.cost

            if (balErr || !enoughBusd) {
                updateProgress(1, false)
                this.loading = false
                return
            }
            updateProgress(1, true)

            const [estimateErr, estimatePrice] = await this.verboseBuyGas({
                lvl: this.activationModal.level,
                priceInEther: this.activationModal.cost,
                onAllowancePending: () => {
                    this.$store.commit("loader/showProcessingModal", {
                        isShown: true,
                        text: this.$t("matrix.activationModal.allowancePending")
                    })
                },
                onBlockchainPending: () => {
                    this.$store.commit("loader/showProcessingModal", {
                        isShown: true,
                        text: `${this.$t("matrix.activationModal.blockchainPendingFirstLine")} <br />
                              ${this.$t("matrix.activationModal.blockchainPendingSecondLine")}`
                    })
                }
            })

            if (estimateErr) {
                updateProgress(2, false)
                this.$swal(estimateErr.message)
                this.loading = false
                this.$store.commit("loader/showProcessingModal", {
                    isShown: false
                })

                return
            }
            updateProgress(2, true)
            this.estimatedGas = Number(estimatePrice).toFixed(4)

            this.$store.commit("loader/showProcessingModal", {
                isShown: true
            })
            const [err, responce] = await this.buyLevel({
                lvl: this.activationModal.level,
                priceInEther: this.activationModal.cost,
                onAllowancePending: () => {
                    this.$store.commit("loader/showProcessingModal", {
                        isShown: true,
                        text: this.$t("matrix.activationModal.allowancePending")
                    })
                },
                onBlockchainPending: () => {
                    this.$store.commit("loader/showProcessingModal", {
                        isShown: true,
                        text: `${this.$t("matrix.activationModal.blockchainPendingFirstLine")} <br />
                              ${this.$t("matrix.activationModal.blockchainPendingSecondLine")}`
                    })
                },
                onTransactionPending: () => {
                    this.$store.commit("loader/showProcessingModal", {
                        isShown: true,
                        text: this.$t("matrix.activationModal.transactionPending")
                    })
                }
            })

            this.$store.commit("loader/showProcessingModal", {
                isShown: false,
            })
            this.loading = false

            if (err) {
                this.$swal(err.message || err)
                if (err.status === 202) {
                    this.closeModal()
                }
                return
            }

            this.closeModal()
        },
        closeModal() {
            this.setActivationModal({ visible: false })
        },
        ...mapMutations("ui", ["setActivationModal"]),
        ...mapActions("crypto", ["checkConnect", "getBalances", "verboseBuyGas", "buyLevel"])
    },
    watch: {
        activationModalVisible() {
            this.process = [null, null, null]
            this.estimatedGas = "0"
        }
    }
}
</script>

<style lang="scss">
.activation-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    backdrop-filter: blur(4px);
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
    &.show {
        opacity: 1;
        pointer-events: all;
    }
    &__inner {
        border-radius: 41px;
        max-width: 540px;
        width: 100%;
        padding: 50px;
        background-color: var(--color-black-800);
        text-align: center;
        position: relative;
        @media screen and (max-width: 576px) {
            padding: 30px 15px;
            max-width: calc(100% - 30px);
            overflow: scroll;
            height: 100%;
            border-radius: 0;
        }
    }
    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__icon {
        margin-bottom: var(--main-offset);
        width: 66px;
        height: 66px;
    }
    &__title {
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        margin-bottom: var(--main-offset);
        @media screen and (max-width: 576px) {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: var(--second-offset);
        }
    }
    &__buy {
        display: inline-block;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: -0.02em;
        color: #d1d4d4;
        margin-bottom: 5px;
        &-lvl {
            display: block;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: -0.02em;
            margin-bottom: 15px;
        }
    }
    &__divider {
        width: 100%;
        height: 1px;
        opacity: 0.2;
        background-color: var(--color-white);
        border-radius: 5px;
        margin-bottom: 15px;
    }
    &__status {
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        &-resolve,
        &-reject {
            width: 21px;
            height: 21px;
            border-radius: 100%;
            border: 1px solid;
            display: none;
            align-items: center;
            justify-content: center;
            padding: 4px;
        }
        &-resolve {
            border-color: var(--color-emerald);
        }
        &-reject {
            border-color: #ff381d;
        }
        &-text {
            display: inline-block;
            font-size: 16px;
            line-height: 24px;
            margin-right: 10px;
            @media screen and (max-width: 576px) {
                font-size: 14px;
            }
        }
        &.resolve {
            .activation-modal__status-text {
                color: var(--color-emerald);
            }
            .activation-modal__status-resolve {
                display: flex;
            }
        }
        &.reject {
            .activation-modal__status-text {
                color: #ff381d;
            }
            .activation-modal__status-reject {
                display: flex;
            }
        }
    }
    &__pay {
        margin-top: 25px;
        width: 100%;
    }
    &__confirm {
        margin-top: 15px;
        text-align: left;
    }
}
</style>
