<template>
  <img
    :src="avatarPath"
    class="avatar-image"
    :alt="[user?.name ? user?.name : 'avatar']"
    @error="defaultAvatarCallback"
    v-if="user"
  />
</template>

<script>
import { createImageHref } from '@/helpers/images'

export default {
  props: {
    user: Object
  },
  computed: {
    avatarPath () {
      return createImageHref(this.user && this.user.avatar)
    }
  },
  methods: {
    defaultAvatarCallback (e) {
        e.target.src = '/assets/avatar2.jpg'
    }
  }
}
</script>

<style scoped>
.avatar-image {
  object-fit: cover;
}
</style>
