<template>
    <div class="level-pagination level-pagination--classic d-flex align-center">
        <button class="level-pagination__prev btn-reset" type="button" @click="handlePrev">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1L1 9.5L9 18" stroke="white" stroke-linecap="round" />
            </svg>
        </button>

        <div class="level-pagination__cycles">
            <div
                class="level-pagination__item d-flex align-center flex-column"
                v-for="(item, idx) in items"
                :key="idx"
                :class="[item === meta.current && 'active', typeof item !== 'number' && 'dotItem']"
                @click="() => typeof item === 'number' && $emit('onChange', item)"
            >
                <span class="level-pagination__item-title">Cycle</span>
                <span class="level-pagination__item-number">
                    <span v-if="typeof item === 'number'" @click="$emit('onChange', item)">{{ item }}</span>
                    <span v-else>...</span>
                </span>
            </div>
        </div>

        <button
            class="level-pagination__next d-flex align-center justify-center btn-reset"
            type="button"
            @click="handleNext"
        >
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L9 9.5L1 18" stroke="white" stroke-linecap="round" />
            </svg>
        </button>
    </div>
</template>

<script>
// import { mapMutations, mapActions } from "vuex"
import { paginationBuilder } from "@/helpers/pagination"
import debounce from "lodash/debounce"

export default {
    data() {
        return {
            wWidth: 0,
        }
    },

    created() {
        this.setWindowDebounce = debounce(this.setWindow, 200)
    },
    mounted() {
        this.setWindow()
        window.addEventListener("resize", this.setWindowDebounce)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.setWindowDebounce)
    },
    props: {
        meta: Object,
    },
    computed: {
        items() {
            const { total, current } = this.meta
            let pages = [5, 7]

            if (this.wWidth <= 625) {
                pages = [3, 5]
            }

            return paginationBuilder(current, total, pages[0], pages[1])
        },
        prevAvailable() {
            return this.meta.current > 1
        },
        nextAvailable() {
            return this.meta.current < this.meta.total
        },
    },
    methods: {
        setWindow() {
            this.wWidth = window.innerWidth
        },
        handlePrev() {
            if (this.prevAvailable) {
                this.$emit("onChange", this.meta.current - 1)
            }
        },
        handleNext() {
            if (this.nextAvailable) {
                this.$emit("onChange", this.meta.current + 1)
            }
        },
    },
}
</script>

<style lang="scss">
.level-pagination {
    margin: 0 auto;
    padding: 6px 8px;
    border-radius: 16px;
    background-color: var(--color-white-3);
    display: flex;
    align-items: center;
    @media screen and (max-width: 625px) {
        padding-left: 0;
        padding-right: 0;
    }
    &__cycles {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 0 var(--second-offset);
        @media screen and (max-width: 625px) {
            gap: 6px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48px;
        height: 50px;
        border-radius: 7px;
        border: 1px solid #fff;
        transition: border 0.25s ease-in-out;
        &-title,
        &-number {
            transition: color 0.25s ease-in-out;
        }

        &-title {
            font-size: 14px;
        }

        &-number {
            font-size: 22px;
            line-height: 1;
        }
        @media screen and (max-width: 625px) {
            width: 42px;
            height: 46px;
            &.dotItem {
                border: 0;
                width: auto;
                .level-pagination__item-title {
                    font-size: 0;
                }
            }
            &-title {
                font-size: 10px;
            }
            &-number {
                font-size: 16px;
            }
        }
    }

    &--classic {
        .level-pagination__item.active {
            border: 1px solid var(--color-emerald);
            color: var(--color-emerald);
        }
    }

    &--boost {
        .level-pagination__item.active {
            border: 1px solid var(--color-tulip);
            color: var(--color-tulip);
        }
    }
}
</style>
